import { useMemo } from "react"
import TablePaginationV2 from "../../../components/generic/TablePaginationV2"
import { ISWListWithPendingNotesOrScreeners } from "../../../models"
import { RoutePaths } from "../../../shared/Utils"
import { Link } from "react-router-dom"
import { Button } from "../../../components/generic/Buttons"

interface SWListProps {
    swList: ISWListWithPendingNotesOrScreeners[]
    searchInProgress: boolean | undefined
    isPendingNotes: boolean
}

const SWComplianceListComponent = (props: SWListProps) => {
    const { swList, searchInProgress, isPendingNotes } = props

    const headerName = useMemo(() => 
        isPendingNotes ? "No. of Pending Notes" : "No. of Pending Screeners", 
        [isPendingNotes]
    );

    const pendingPercentage = (props: any) => {
        const percentage = props.getValue()
        const pendingPercentageClass = percentage > 70 ? "text-red-500" : (percentage > 30 ? "text-yellow-500" : "text-green-500")
        return (
            <span className={`${pendingPercentageClass}`}>
                {`${percentage}%`}
            </span>
        )
    }

    const data = swList.map((sw) => {
        return {
            id: sw.therapistId,
            name: `${sw.therapistName}`,
            pendingScreeners: sw.pending,
            pendingPercentage: sw.percentage
        }
    });

    const columns = useMemo(
        () => [
            { header: "Social Worker", accessorKey: "name"},
            { header: headerName, accessorKey: "pendingScreeners" },
            { header: "Percentage Pending", accessorKey: "pendingPercentage", cell: (props: any) => pendingPercentage(props)},
            { header: "", accessorKey: "id", cell: (props: any)=>  {
                   const therapistId = props.getValue();
                   return (
                    <Link to={RoutePaths.careCoordinator.compliance.clinicalNotesPendingList.replace(":therapistId", therapistId)}>
                            <Button className={"!bg-sjOrange !text-white !ml-2"}>View Details</Button>
                        </Link>
                   )
                }
            }
        ],
        [headerName]
    );

    return <>
    <div>
        <TablePaginationV2 
            columns={columns} 
            data={data} 
            searchInProgress={searchInProgress}
            possibleColumnsToHide={{id: isPendingNotes ? true : false}}
        />
    </div>
</>
}

export default SWComplianceListComponent