import React, {useCallback, useEffect, useState} from "react";
import StatusTabs from "../../../components/generic/StatusTabs";
import {Roles, SessionTypes} from "../../../models";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {RoutePaths} from "../../../shared/Utils";
import PaginationV2 from "../../../components/generic/PaginationV2";
import {StateParams} from "../../../store/reducers";
import {useDispatch, useSelector} from "react-redux";
import ClinicalNotesPendingListComponent from "./ClinicalNotesPendingListComponent";
import BackIcon from "../../../assets/images/common/back.svg";
import {
    fetchClinicalNotesComplianceStats,
    fetchPendingClinicalNotes,
} from "../../../store/actions/counsellor/non-physician-compliance.action";
import { fetchPractices } from "../../../store/actions/counsellor/prospects.action";
import { DateFilters } from "../care-coordinator/SWComplianceList";
import SWComplianceListFilter from "../care-coordinator/SWComplianceListFilter";
import { BsFillInfoCircleFill } from "react-icons/bs";

const SocialWorkerTabs = [
    {label: "All", value: "all", className: ''},
    {label: "Intake", value: "Intake", className: ''},
    {label: "Treatment Plan", value: "Treatment_Plan", className: ''},
    {label: "Progress Note", value: "Follow_Up", className: ''},
    {label: "Catch Up", value: "Catchup", className: ''},
];

const ClinicalNotesPendingListScreen = () => {

    const {
        role
    } = useSelector((state: StateParams) => state.account);
    const {
        isClinicalNotesComplianceStatsInProgress,
        clinicalNotesComplianceStats,
        pendingClinicalNoteCount,
        pendingClinicalNoteList,
        isPendingClinicalNotesFetchInProgress
    } = useSelector((state: StateParams) => state.nonPhysicianCompliance);
    const { practices } = useSelector((state: StateParams) => state.prospects);
    const totalNotesCount = clinicalNotesComplianceStats?.totalPendingCount || 0;
    const isCareCoordinator = role === 'Care_Coordinator'

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams()
    const [backLink, setBackLink] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get("tab") as SessionTypes || 'all';
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [currentTab, setCurrentTab] = useState<SessionTypes>(defaultTab as SessionTypes);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [practiceIds, setPracticeIds] = useState<string[]>([]);
    const [filters, setFilters] = useState<DateFilters>({
        startDate: undefined,
        endDate: undefined
    });

    useEffect(() => {
        dispatch(fetchPractices());
    }, [dispatch]);

    useEffect(() => {
        setSearchParams({page: pageNumber.toString(), tab: currentTab});
    }, [navigate, currentTab, pageNumber]);

    const handleTabSelection = useCallback((tab: SessionTypes) => {
        setCurrentTab(tab);
        setPageNumber(1);
    }, []);

    const getStatusTabClassName = useCallback((value: string) => {
        return currentTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black";
    }, [currentTab]);

    useEffect(() => {
        const pathComponents = location.pathname.split("/");
        if (pathComponents.includes('coordinator')) {
            setBackLink(`${RoutePaths.careCoordinator.compliance.root}`);
        } else {
            setBackLink(`${RoutePaths.collaborativeCare.compliance.root}`);
        }
    }, [location]);

    useEffect(() => {
        getPendingClinicalNoteList();
    }, [pageNumber, recordsPerPage, searchText, currentTab, params, practiceIds, filters]);

    useEffect(() => {
        getPendingClinicalNoteStats();
    }, [searchText, params, practiceIds, filters]);

    const getPendingClinicalNoteList = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText || currentTab || practiceIds.length > 0 || filters) {
            dispatch(fetchPendingClinicalNotes({pageNumber, recordsPerPage, searchText, noteType: currentTab, therapistId: params.therapistId, practiceIds, filters}));
        }
    }, [dispatch, pageNumber, recordsPerPage, searchText, currentTab, params, practiceIds, filters]);

    const getPendingClinicalNoteStats = useCallback(() => {
        dispatch(fetchClinicalNotesComplianceStats({searchText, therapistId: params.therapistId, practiceIds, filters}));
    }, [dispatch, searchText, params, practiceIds, filters]);

    useEffect(() => {
        setPageNumber(1);
    }, [searchText, practiceIds, filters]);

    const renderTabs = useCallback(() => {
        const pendingIntakeCount = clinicalNotesComplianceStats?.pendingIntakeCount || 0;
        const pendingTreatmentCount = clinicalNotesComplianceStats?.pendingTreatmentCount || 0;
        const pendingProgressCount = clinicalNotesComplianceStats?.pendingProgressCount || 0;
        const pendingCatchupCount = clinicalNotesComplianceStats?.pendingCatchupCount || 0;
        const totalPendingNotesCount = clinicalNotesComplianceStats?.totalPendingCount || 0;
        const tabCount = {
            all: totalPendingNotesCount,
            Intake: pendingIntakeCount,
            Treatment_Plan: pendingTreatmentCount,
            Follow_Up: pendingProgressCount,
            Catchup: pendingCatchupCount
        }
        return <StatusTabs
            tabs={SocialWorkerTabs}
            handleSelectedFilters={(value) => {
                handleTabSelection(value as SessionTypes);
            }}
            getTabClassName={getStatusTabClassName}
            tabCount={tabCount}
        />
    }, [currentTab, clinicalNotesComplianceStats]);

    return (
        <div className="w-full p-4 h-full bg-[#F7F8FA]">
            <div className={"flex justify-between pb-4 items-center"}>
                <div className={"flex flex-row gap-2 items-center"}>
                    <Link to={backLink}>
                        <img src={BackIcon} width={24} height={24} alt="Go Back"
                             className={"hover:cursor-pointer"}/>
                    </Link>
                    <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                            <span
                                className="text-xl">
                                Pending Notes ({isClinicalNotesComplianceStatsInProgress ? 'Loading...' : totalNotesCount})
                            </span>
                    </div>
                </div>
            </div>
            <div className={"flex justify-between pb-2"}>
                <div className="inline-flex rounded-md shadow-sm ">
                    {renderTabs()}
                </div>
                <div className='flex flex-row gap-x-2 items-center'>
                    <SWComplianceListFilter 
                            practices={practices}
                            setPracticeIds={setPracticeIds}
                            setSearchText={setSearchText}
                            recordsCount={pendingClinicalNoteCount}
                            setCurrentPage={setPageNumber}
                            onFilterChange={(startDate, endDate) => setFilters({startDate, endDate})}
                            isPendingNotes={true}
                        />
                </div>
            </div>
            {isCareCoordinator ? <div className="flex flex-row mt-2 items-center gap-x-2 mb-2">
                    <BsFillInfoCircleFill color="#575F6E" width={16} height={16}/>
                    <span className="text-base text-sjLightGrey font-light">Please contact the Social Worker and request that they submit the notes.</span>
            </div> : null }
            <ClinicalNotesPendingListComponent
                notes={pendingClinicalNoteList}
                currentPageHandler={setPageNumber}
                searchInProgress={isPendingClinicalNotesFetchInProgress}
                activeTab={currentTab}
            />
            {pendingClinicalNoteList && pendingClinicalNoteList.length > 0 &&
                <PaginationV2
                    totalRows={pendingClinicalNoteCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={pageNumber}
                    currentPageHandler={setPageNumber}
                    showPages={false}
                />
            }
        </div>
    );
}

export default ClinicalNotesPendingListScreen;
