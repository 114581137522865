import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {fetchPatientLatestScreeningSummary} from "../../../store/actions/care-coordinator/patients.action";
import {StateParams} from "../../../store/reducers";
import ScreenerSummary from "../../../components/patients/physician/ScreenerSummary";

const PotentialPatientScreenerSummary = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const {patientLatestScreeningSummary} = useSelector((state: StateParams) => state.coordinatorPatients)
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const [selectedTab, setSelectedTab] = useState<string>()
    const summaryComponentRef = useRef(null);

    useEffect(() => {
        const practiceId = searchParams.get('practiceId')
        if (params.patientId && practiceId) {
            dispatch(fetchPatientLatestScreeningSummary(params.patientId, practiceId));
        }
    }, [params.patientId])

    return (
        <main className="relative min-h-screen mx-auto flex flex-row">
            <div className="flex-1">
                {/* eslint-disable-next-line react/jsx-no-undef */}
                {patientLatestScreeningSummary && patientLatestScreeningSummary.summary
                    ? <ScreenerSummary patientDetails={patientLatestScreeningSummary.patient} summary={patientLatestScreeningSummary.summary} summaryComponentRef={summaryComponentRef} updateSelectedIndex={setSelectedTab} role={role}/>
                    : null}
                </div>
        </main>
    )
}
export default PotentialPatientScreenerSummary;
