import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import React, {useCallback, useEffect} from "react";
import {fetchPatientWithSummary} from "../../../store/actions/physician/patients.action";
import {StateParams} from "../../../store/reducers";
import Prospect from "../../../components/patients/physician/Prospect";
import {Link, useNavigate} from "react-router-dom";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {RoutePaths} from "../../../shared/Utils";
import { fetchSourcePatientDetails } from "../../../store/actions/counsellor/patients.action";
import {IPatient, IProspect} from "../../../models";
import BackIcon from "../../../assets/images/common/back.svg";
import * as _ from "lodash";

const ProspectScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { patient, summary } = useSelector((state: StateParams) => state.physicianPatients);

    useEffect(() => {
        if (params.patientId) {
            dispatch(fetchPatientWithSummary(params.patientId))
            dispatch(fetchSourcePatientDetails(params.patientId))
        }
    }, [params.patientId]);

    function renderBackButton(patient: IPatient) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Profile {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    return (
            <div className="w-full p-4 h-full bg-[#F7F8FA]">
                <div className={"py-1 floating-section"}>
                    {patient ? renderBackButton(patient) : null}
                </div>
                <div className="mt-4">
                    {patient && summary ? <Prospect patient={patient} summary={summary} /> : null}
                </div>
            </div>
   )
}

export default ProspectScreen
