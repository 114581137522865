import _ from "lodash";
import {useEffect, useState} from "react";
import {IoArrowBackCircleOutline} from "react-icons/io5"
import {batch, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom"
import { toast } from "react-toastify";
import { useStatusChange } from "../../../hooks/useStatusChange";
import useToastNotifications from "../../../hooks/useToastNotifications";
import { NotesStatus, Roles, SessionTypes } from "../../../models";
import { updateSessionId, updateSessionStatus } from "../../../store/actions/counsellor/appointments.action";
import {fetchPatient, fetchProgressNotes} from "../../../store/actions/counsellor/patients.action";
import { clearNotesError } from "../../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../../store/reducers";
import { Button } from "../../generic/Buttons";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import ProgressNotes from "./ProgressNotes";

const PatientProgressNotesComponent: React.FC<{ patientProspectId?: string, notesSessionId?: string}> = ({patientProspectId: patientId, notesSessionId}) => {
    const {patient, notesStatus, sessionNotesId} = useSelector((state: StateParams) => state.patients)
    const { statusUpdateError, isNotesStatusUpdated } = useSelector((state: StateParams) => state.prospects)
    const role = useSelector((state: StateParams) => state.account.role)
    const {appointmentStatus, sessionId} = useSelector((state: StateParams) => state.appointments)
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const [notesId, setNotesId] = useState<string | null>(searchParams.get('notesId'))
    const [isPsychiatrist, setIsPsychiatrist] = useState<boolean>(Roles.Psychiatrist === role)
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.FollowUp)
    useToastNotifications({
        toastId: 'progress-notes-status-success-toast',
        clearErrorAction: clearNotesError,
        errorCondition: statusUpdateError,
        errorMessage: statusUpdateError ? statusUpdateError : 'The notes failed to submit.',
        successCondition: isNotesStatusUpdated,
        successMessage: 'Notes has been locked successfully.'
    })

    useEffect(()=> {
        if(notesSessionId){
            dispatch(updateSessionId({sessionId: notesSessionId}))
        }
    },[notesSessionId])

    useEffect(() => {
        const id = patientId || params.patientProspectId
        if(id) {
            batch(() => {
                dispatch(fetchPatient(id))
                dispatch(fetchProgressNotes(id, notesId || ''))
            })
        }
    }, [patientId, params])

    useEffect(() => {
        if(notesStatus) {
            const canEditNotes = notesStatus === NotesStatus.LOCKED ? false : isPsychiatrist || role === Roles.Physician ? false : true
            setCanEdit(canEditNotes)
        }
    }, [notesStatus])

    const handleStatusChange = () => {
        const id = patientId || params.patientProspectId
        const documentId = notesId || sessionNotesId //notesId - when coming from journey, treatmentId = when coming from session
        if(id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(NotesStatus.LOCKED, documentId, id)
                if(sessionId){
                    dispatch(updateSessionStatus({ sessionId, status: 'finished' }))
                }
            })
        }
    }

    const handleSessionStatus = () => {
        toast("Notes has been saved successfully.");
        if (sessionId) {
            dispatch(updateSessionStatus({ sessionId, status: 'finished' }));
        }
    }

    const renderCompleteButton = () => <>
        {(role === Roles.Counsellor || role === Roles.Admin) && notesStatus !== NotesStatus.LOCKED && !isZoomSessionActive && <Button className={"!bg-sjOrange !text-white"}
                                                                                                                                      onClick={() => handleSessionStatus()}>Save</Button>}
    </>

    const renderLockButton = () => <>
        {(role === Roles.Counsellor || role === Roles.Admin) && notesStatus !== NotesStatus.LOCKED && !isZoomSessionActive && <Button className={"!bg-sjOrange !text-white"}
                                                                                                                                      onClick={handleStatusChange}>Submit</Button>}
    </>

    const actionComponent = () => {
        return <>
            {renderCompleteButton()}
            {renderLockButton()}
        </>
    }

    const renderNotesSection = () => {
        return <>
            {patient && <ProgressNotes patientId={patient.id} canEdit={canEdit} isPsychiatrist={isPsychiatrist} sourcePatientId={patient.sourcePatientId} />}
        </>
    }

    return (
        <ClinicalNotesComponent title="Progress Notes" patient={patient} actionComponent={actionComponent()} notesSessionId={notesSessionId}>
            {renderNotesSection()}
        </ClinicalNotesComponent>
    )
}

export default PatientProgressNotesComponent
