import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaginationV2 from "../../../components/generic/PaginationV2";
import { StateParams } from "../../../store/reducers";
import { RoutePaths } from "../../../shared/Utils";
import StatusTabs from "../../../components/generic/StatusTabs";
import { fetchComplianceSWList } from "../../../store/actions/care-coordinator/compliance.action";
import SWComplianceListComponent from "./SWComplianceListComponent";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import { fetchPractices } from "../../../store/actions/counsellor/prospects.action";
import Select from "react-select";
import SWComplianceListFilter from "./SWComplianceListFilter";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { DateTime } from "luxon";

export type DateFilters = {
    startDate?: string,
    endDate?: string
}

const month = DateTime.now().monthLong
const year = DateTime.now().year

const TABS = [
    { label: "Pending Notes", value: "pendingNotes" },
    { label: `Pending ${month} Screeners`, value: "pendingMonthlyScreeners" }
];

const SWComplianceList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {swList, tabCount, isComplianceSWListInProgress} = useSelector((state: StateParams) => state.careCoordinatorCompliance)
    const recordsCount = useSelector((state: StateParams) => state.careCoordinatorCompliance.swListCount)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "pendingNotes");
    const isPendingNotes = activeTab.includes("pendingNotes")
    const [practiceIds, setPracticeIds] = useState<string[]>([]);
    const { practices } = useSelector((state: StateParams) => state.prospects);
    const [filters, setFilters] = useState<DateFilters>({
        startDate: undefined,
        endDate: undefined
    });

    useEffect(() => {
        dispatch(fetchPractices());
    }, [dispatch]);

    useEffect(() => {
        if (currentPage || recordsPerPage || searchText || practiceIds.length > 0 || filters) {
            dispatch(fetchComplianceSWList({pageNumber: currentPage, recordsPerPage, searchText, isPendingNotes, practiceIds, filters}))
        }
    }, [currentPage, recordsPerPage, searchText, activeTab, practiceIds, filters]);

    useEffect(() => {
        if (searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
            setActiveTab(searchParams.get("tab") || "pendingNotes")
        }
    }, [searchParams])

    useEffect(() => {
        navigate(`${RoutePaths.careCoordinator.compliance.root}?page=${currentPage}&tab=${activeTab}`)
    }, [currentPage, activeTab]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchText, practiceIds, filters]);

    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        setActiveTab(status);
        setFilters({startDate: undefined, endDate: undefined})
    }

    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"
    };

    return (
        <div className={"w-full p-4 h-full bg-sjGreyishWhite"}>
        <h1 className="text-xl"> Social Worker Compliance Rate </h1>
            <div className="mt-4">
                <div className={"flex justify-between pb-2 items-center"}>
                    <div className={"flex justify-between gap-x-2 items-center"}>
                        <div className="inline-flex rounded-md shadow-sm">
                            <StatusTabs
                                tabs={TABS}
                                handleSelectedFilters={handleSelectedFilters}
                                getTabClassName={getStatusTabClassName}
                                tabCount={tabCount}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end items-center gap-3">
                        <SWComplianceListFilter 
                            practices={practices}
                            setPracticeIds={setPracticeIds}
                            setSearchText={setSearchText}
                            recordsCount={recordsCount}
                            setCurrentPage={setCurrentPage}
                            onFilterChange={(startDate, endDate) => setFilters({startDate, endDate})}
                            isPendingNotes={isPendingNotes}
                        />
                    </div>
                </div>
                <div className="flex flex-row mt-2 items-center gap-x-2 mb-2">
                    <BsFillInfoCircleFill color="#575F6E" width={16} height={16}/>
                    <span className="text-base text-sjLightGrey font-light">Each social worker statistics based on their pending {isPendingNotes ? 'notes' : 'screeners'} and it’s percentages {isPendingNotes ? '' : <><span>for </span><span className="font-bold">{`${month} ${year}`}</span></>}</span>
                </div>
            <SWComplianceListComponent swList={swList} searchInProgress={isComplianceSWListInProgress} isPendingNotes={isPendingNotes}/>
            {swList && swList.length > 0 &&
                <PaginationV2
                    totalRows={recordsCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={currentPage}
                    currentPageHandler={setCurrentPage}
                    showPages={false}
                />
            }
            </div>
        </div>
    )

}

export default SWComplianceList