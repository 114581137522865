import React from "react";
import {IProspect, ISummary} from "../../models";
import ProspectProfile from "./ProspectProfile";
import ScreenerResults from "../screeners/ScreenerResults";

interface PatientProps {
    prospect: IProspect
    summary: ISummary
}

const Prospect : React.FC<PatientProps> = ({prospect, summary}) => {

    return <>
        <ProspectProfile prospect={prospect} isReferredPatient={true}/>
        <ScreenerResults prospect={prospect} summary={summary}/>
    </>
}

export default Prospect
