import { AnyAction } from "redux";
import { IPatient, IPatientMaster, IPatientMasters, IPatientWithSummary, IPotentialReferralPatient, IPotentialReferralPatientBasicDetails, PatientMasterIndexRequest } from "../../../models";
import { Filters } from "../../../screens/prospects/care-coordinator/PotentialPatientsScreen";

export const FETCH_POTENTIAL_REFERRAL_PATIENTS = "FETCH_POTENTIAL_REFERRAL_PATIENTS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS = "FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS = "FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS"
export const FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED = "FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED"
export const UPDATE_POTENTIAL_REFERRAL_PATIENT = "UPDATE_POTENTIAL_REFERRAL_PATIENT"
export const UPDATE_PATIENT_BASIC_INFO = "UPDATE_PATIENT_BASIC_INFO"

export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY"

export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS"
export const FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED = "FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED"

export const FETCH_PATIENT_BASIC_INFORMATION = "FETCH_PATIENT_BASIC_INFORMATION"

export const FETCH_PATIENT_BASIC_INFORMATION_SUCCESS = "FETCH_PATIENT_BASIC_INFORMATION_SUCCESS"
export const FETCH_PATIENT_BASIC_INFORMATION_FAILED = "FETCH_PATIENT_BASIC_INFORMATION_FAILED"

export const FETCH_PATIENT_MASTER_LIST = "FETCH_PATIENT_MASTER_LIST"
export const FETCH_PATIENT_MASTER_LIST_SUCCESS = "FETCH_PATIENT_MASTER_LIST_SUCCESS"
export const FETCH_PATIENT_MASTER_LIST_FAILED = "FETCH_PATIENT_MASTER_LIST_FAILED"

export const TRANSFER_INTAKE = "TRANSFER_INTAKE"
export const TRANSFER_INTAKE_SUCCESS = "TRANSFER_INTAKE_SUCCESS"
export const TRANSFER_INTAKE_FAILED = "TRANSFER_INTAKE_FAILED"
export const TRANSFER_INTAKE_INPROGRESS="TRANSFER_INTAKE_INPROGRESS"



export const fetchPotentialReferralPatientsInProgress = (): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS }
}
export const fetchPotentialReferralPatients = (currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] | undefined }, advancedFilters?: Filters): AnyAction => {
    const searchFilters = filters && filters.status ? filters : undefined
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS, payload: { currentPage, recordsPerPage, searchText, filters: searchFilters, advancedFilters } }
}
export const fetchingPotentialReferralPatientsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED, payload }
}
export const fetchedPotentialReferralPatients = (patients: IPotentialReferralPatient[], count: number, statusCount: Record<string, number>): AnyAction => {
    return { type: FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS, payload: { patients, count, statusCount } }
}

export const fetchingPatientLatestScreeningSummaryFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED, payload }
}

export const fetchedPatientLatestScreeningSummary = (screeningSummary: IPatientWithSummary): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS, payload: { screeningSummary } }
}

export const fetchPatientLatestScreeningSummary = (patientId: string, practiceId: string): AnyAction => {
    return { type: FETCH_PATIENT_LATEST_SCREENING_SUMMARY, payload: { patientId, practiceId } }
}

export const updatePotentialRefferalPatient = (patient: IPotentialReferralPatient): AnyAction => {
    return { type: UPDATE_POTENTIAL_REFERRAL_PATIENT, payload: { patient } }
}

export const updatePatientBasicInfo = (contactPhoneNumber: string | null | undefined): AnyAction => {
    return { type: UPDATE_PATIENT_BASIC_INFO, payload: { contactPhoneNumber } }
}

export const fetchingPatientBasicInformationFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION_FAILED, payload }
}

export const fetchedPatientBasicInformation = (patient: IPotentialReferralPatientBasicDetails): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION_SUCCESS, payload: { patient } }
}

export const fetchPatientBasicInformation = (patientId: string): AnyAction => {
    return { type: FETCH_PATIENT_BASIC_INFORMATION, payload: { patientId } }
}

export const fetchingPatientMasterFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST_FAILED, payload }
}

export const fetchedPatientMasterList = (patients: IPatientMaster[], count: number): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST_SUCCESS, payload: { patients, count } }
}

export const fetchPatientMasterList = (request: PatientMasterIndexRequest): AnyAction => {
    return { type: FETCH_PATIENT_MASTER_LIST, payload: { request } }
}
export const transferIntake = (sourcePatientId: string, therapistId: string | null, patientStatus: string): AnyAction => {
    return { type: TRANSFER_INTAKE, payload: {sourcePatientId, therapistId, patientStatus } }
}
export const transferingIntakeFailed = (payload: { error: string }): AnyAction => {
    return { type: TRANSFER_INTAKE_FAILED, payload }
}
export const transferedIntake = (noteId: string): AnyAction => {
    return { type: TRANSFER_INTAKE_SUCCESS, payload: { noteId } }
}