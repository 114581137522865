import {AnyAction} from "redux";
import {
    CatchupNotes,
    CatchupNotesResponse,
    CurrentQuestion,
    DischargedPatients,
    IAssignedScreeners,
    IProspect,
    ITherapistPatient,
    LocationIndexItem,
    NotesStatus,
    PatientAccount,
    PatientDailyCheckinInsights,
    PatientJourney,
    PatientSessionInsights,
    PsychiatristNotes,
    PsychiatristNotesResponse,
    SWScreenerInsights,
    SWUserScreenerRequest,
    ScreenerProviderType,
    SourcePatient,
    TreatmentPlanNotes
} from "../../../models";

export const SEARCH_PATIENTS="SEARCH_PATIENTS"
export const SEARCH_PATIENTS_IN_PROGRESS="SEARCH_PATIENTS_IN_PROGRESS"
export const SEARCH_PATIENTS_SUCCESS="SEARCH_PATIENTS_SUCCESS"
export const SEARCH_PATIENTS_FAILED="SEARCH_PATIENTS_FAILED"

export const FETCH_PATIENTS="FETCH_PATIENTS"
export const FETCH_PATIENTS_IN_PROGRESS="FETCH_PATIENTS_IN_PROGRESS"
export const FETCH_PATIENTS_SUCCESS="FETCH_PATIENTS_SUCCESS"
export const FETCH_PATIENTS_FAILED="FETCH_PATIENTS_FAILED"

export const FETCH_PATIENT="FETCH_PATIENT"
export const FETCH_PRACTICE_PATIENT="FETCH_PRACTICE_PATIENT"
export const FETCH_PATIENT_IN_PROGRESS="FETCH_PATIENT_IN_PROGRESS"
export const FETCH_PATIENT_SUCCESS="FETCH_PATIENT_SUCCESS"
export const FETCH_PATIENT_FAILED="FETCH_PATIENT_FAILED"
export const CLEAR_PATIENT_AND_SOURCE_PATIENT="CLEAR_PATIENT_AND_SOURCE_PATIENT"

export const FETCH_PATIENT_WITH_SUMMARY="FETCH_PATIENT_WITH_SUMMARY"
export const FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS="FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS"
export const FETCH_PATIENT_WITH_SUMMARY_SUCCESS="FETCH_PATIENT_WITH_SUMMARY_SUCCESS"
export const FETCH_PATIENT_WITH_SUMMARY_FAILED="FETCH_PATIENT_WITH_SUMMARY_FAILED"

export const FETCH_PATIENT_BY_SOURCE_PATIENT_ID="FETCH_PATIENT_BY_SOURCE_PATIENT_ID"
export const FETCH_PATIENT_BY_SOURCE_PATIENT_ID_IN_PROGRESS="FETCH_PATIENT_BY_SOURCE_PATIENT_ID_IN_PROGRESS"
export const FETCH_PATIENT_BY_SOURCE_PATIENT_ID_SUCCESS="FETCH_PATIENT_BY_SOURCE_PATIENT_ID_SUCCESS"
export const FETCH_PATIENT_BY_SOURCE_PATIENT_ID_FAILED="FETCH_PATIENT_BY_SOURCE_PATIENT_ID_FAILED"

export const FETCH_TREATMENT_NOTES="FETCH_TREATMENT_NOTES"
export const FETCH_TREATMENT_NOTES_IN_PROGRESS="FETCH_TREATMENT_NOTES_IN_PROGRESS"
export const FETCH_TREATMENT_NOTES_SUCCESS="FETCH_TREATMENT_NOTES_SUCCESS"
export const FETCH_TREATMENT_NOTES_FAILED="FETCH_TREATMENT_NOTES_FAILED"

export const FETCH_PROGRESS_NOTES="FETCH_PROGRESS_NOTES"
export const FETCH_PROGRESS_NOTES_IN_PROGRESS="FETCH_PROGRESS_NOTES_IN_PROGRESS"
export const FETCH_PROGRESS_NOTES_SUCCESS="FETCH_PROGRESS_NOTES_SUCCESS"
export const FETCH_PROGRESS_NOTES_FAILED="FETCH_PROGRESS_NOTES_FAILED"

export const SAVE_TREATMENT_NOTES="SAVE_TREATMENT_NOTES"
export const SAVE_TREATMENT_NOTES_IN_PROGRESS="SAVE_TREATMENT_NOTES_IN_PROGRESS"
export const SAVE_TREATMENT_NOTES_SUCCESS="SAVE_TREATMENT_NOTES_SUCCESS"
export const SAVE_TREATMENT_NOTES_FAILED="SAVE_TREATMENT_NOTES_FAILED"

export const SAVE_PROGRESS_NOTES="SAVE_PROGRESS_NOTES"
export const SAVE_PROGRESS_NOTES_IN_PROGRESS="SAVE_PROGRESS_NOTES_IN_PROGRESS"
export const SAVE_PROGRESS_NOTES_SUCCESS="SAVE_PROGRESS_NOTES_SUCCESS"
export const SAVE_PROGRESS_NOTES_FAILED="SAVE_PROGRESS_NOTES_FAILED"

export const REVIEW_AND_SUBMIT_TREATMENT_PLAN="REVIEW_AND_SUBMIT_TREATMENT_PLAN"
export const REVIEW_AND_SUBMIT_TREATMENT_PLAN_IN_PROGRESS="REVIEW_AND_SUBMIT_TREATMENT_PLAN_IN_PROGRESS"
export const REVIEW_AND_SUBMIT_TREATMENT_PLAN_SUCCESS="REVIEW_AND_SUBMIT_TREATMENT_PLAN_SUCCESS"
export const REVIEW_AND_SUBMIT_TREATMENT_PLAN_FAILED="REVIEW_AND_SUBMIT_TREATMENT_PLAN_FAILED"

export const FETCH_SAFETY_PLAN_NOTES="FETCH_SAFETY_PLAN_NOTES"
export const FETCH_SAFETY_PLAN_NOTES_IN_PROGRESS="FETCH_SAFETY_PLAN_NOTES_IN_PROGRESS"
export const FETCH_SAFETY_PLAN_NOTES_SUCCESS="FETCH_SAFETY_PLAN_NOTES_SUCCESS"
export const FETCH_SAFETY_PLAN_NOTES_FAILED="FETCH_SAFETY_PLAN_NOTES_FAILED"

export const SAVE_SAFETY_PLAN_NOTES="SAVE_SAFETY_PLAN_NOTES"
export const SAVE_SAFETY_PLAN_NOTES_IN_PROGRESS="SAVE_SAFETY_PLAN_NOTES_IN_PROGRESS"
export const SAVE_SAFETY_PLAN_NOTES_SUCCESS="SAVE_SAFETY_PLAN_NOTES_SUCCESS"
export const SAVE_SAFETY_PLAN_NOTES_FAILED="SAVE_SAFETY_PLAN_NOTES_FAILED"

export const FETCH_PATIENT_JOURNEY="FETCH_PATIENT_JOURNEY"
export const FETCH_PATIENT_JOURNEY_IN_PROGRESS="FETCH_PATIENT_JOURNEY_IN_PROGRESS"
export const FETCH_PATIENT_JOURNEY_SUCCESS="FETCH_PATIENT_JOURNEY_SUCCESS"
export const FETCH_PATIENT_JOURNEY_FAILED="FETCH_PATIENT_JOURNEY_FAILED"

export const FETCH_SESSION_INSIGHTS="FETCH_SESSION_INSIGHTS"
export const FETCH_SESSION_INSIGHTS_IN_PROGRESS="FETCH_SESSION_INSIGHTS_IN_PROGRESS"
export const FETCH_SESSION_INSIGHTS_SUCCESS="FETCH_SESSION_INSIGHTS_SUCCESS"
export const FETCH_SESSION_INSIGHTS_FAILED="FETCH_SESSION_INSIGHTS_FAILED"

export const FETCH_DAILY_CHECKIN_INSIGHTS="FETCH_DAILY_CHECKIN_INSIGHTS"
export const FETCH_DAILY_CHECKIN_INSIGHTS_IN_PROGRESS="FETCH_DAILY_CHECKIN_INSIGHTS_IN_PROGRESS"
export const FETCH_DAILY_CHECKIN_INSIGHTS_SUCCESS="FETCH_DAILY_CHECKIN_INSIGHTS_SUCCESS"
export const FETCH_DAILY_CHECKIN_INSIGHTS_FAILED="FETCH_DAILY_CHECKIN_INSIGHTS_FAILED"

export const FETCH_SW_SCREENERS_INSIGHTS="FETCH_SW_SCREENERS_INSIGHTS"
export const FETCH_SW_SCREENERS_INSIGHTS_IN_PROGRESS="FETCH_SW_SCREENERS_INSIGHTS_IN_PROGRESS"
export const FETCH_SW_SCREENERS_INSIGHTS_SUCCESS="FETCH_SW_SCREENERS_INSIGHTS_SUCCESS"
export const FETCH_SW_SCREENERS_INSIGHTS_FAILED="FETCH_SW_SCREENERS_INSIGHTS_FAILED"
export const CLEAR_SW_SCREENER_INSIGHTS="CLEAR_SW_SCREENER_INSIGHTS"

export const SET_TREATMENT_PLAN_SCREENERS="SET_TREATMENT_PLAN_SCREENERS"
export const UPDATE_LATEST_NOTES_STATUS="UPDATE_LATEST_NOTES_STATUS"
export const CLEAR_TOAST_NOTES_STATUS="CLEAR_TOAST_NOTES_STATUS"

export const FETCH_CATCHUP_NOTES="FETCH_CATCHUP_NOTES"
export const FETCH_CATCHUP_NOTES_IN_PROGRESS="FETCH_CATCHUP_NOTES_IN_PROGRESS"
export const FETCH_CATCHUP_NOTES_SUCCESS="FETCH_CATCHUP_NOTES_SUCCESS"
export const FETCH_CATCHUP_NOTES_FAILED="FETCH_CATCHUP_NOTES_FAILED"

export const SAVE_CATCHUP_NOTES="SAVE_CATCHUP_NOTES"
export const SAVE_CATCHUP_NOTES_IN_PROGRESS="SAVE_CATCHUP_NOTES_IN_PROGRESS"
export const SAVE_CATCHUP_NOTES_SUCCESS="SAVE_CATCHUP_NOTES_SUCCESS"
export const SAVE_CATCHUP_NOTES_FAILED="SAVE_CATCHUP_NOTES_FAILED"

export const SAVE_PSYCHIATRIST_NOTES="SAVE_PSYCHIATRIST_NOTES"
export const SAVE_PSYCHIATRIST_NOTES_IN_PROGRESS="SAVE_PSYCHIATRIST_NOTES_IN_PROGRESS"
export const SAVE_PSYCHIATRIST_NOTES_SUCCESS="SAVE_PSYCHIATRIST_NOTES_SUCCESS"
export const SAVE_PSYCHIATRIST_NOTES_FAILED="SAVE_PSYCHIATRIST_NOTES_FAILED"

export const FETCH_PSYCHIATRIST_NOTES="FETCH_PSYCHIATRIST_NOTES"
export const FETCH_PSYCHIATRIST_NOTES_IN_PROGRESS="FETCH_PSYCHIATRIST_NOTES_IN_PROGRESS"
export const FETCH_PSYCHIATRIST_NOTES_SUCCESS="FETCH_PSYCHIATRIST_NOTES_SUCCESS"
export const FETCH_PSYCHIATRIST_NOTES_FAILED="FETCH_PSYCHIATRIST_NOTES_FAILED"
export const FETCH_ASSIGNED_SCREENERS="FETCH_ASSIGNED_SCREENERS"
export const FETCH_ASSIGNED_SCREENERS_IN_PROGRESS="FETCH_ASSIGNED_SCREENERS_IN_PROGRESS"
export const FETCH_ASSIGNED_SCREENERS_SUCCESS="FETCH_ASSIGNED_SCREENERS_SUCCESS"
export const FETCH_ASSIGNED_SCREENERS_FAILED="FETCH_ASSIGNED_SCREENERS_FAILED"

export const FETCH_SCREENER_Q_AND_A="FETCH_SCREENER_Q_AND_A"
export const FETCH_SCREENER_Q_AND_A_IN_PROGRESS="FETCH_SCREENER_Q_AND_A_IN_PROGRESS"
export const FETCH_SCREENER_Q_AND_A_SUCCESS="FETCH_SCREENER_Q_AND_A_SUCCESS"
export const FETCH_SCREENER_Q_AND_A_FAILED="FETCH_SCREENER_Q_AND_A_FAILED"

export const SAVE_SCREENER_ANSWER="SAVE_SCREENER_ANSWER"
export const SAVE_SCREENER_ANSWER_IN_PROGRESS="SAVE_SCREENER_ANSWER_IN_PROGRESS"
export const SAVE_SCREENER_ANSWER_SUCCESS="SAVE_SCREENER_ANSWER_SUCCESS"
export const SAVE_SCREENER_ANSWER_FAILED="SAVE_SCREENER_ANSWER_FAILED"

export const CLEAR_SCREENER_QUESTIONS="CLEAR_SCREENER_QUESTIONS"

export const UPDATE_PATIENT_INFO="UPDATE_PATIENT_INFO"
export const UPDATE_PATIENT_INFO_IN_PROGRESS="UPDATE_PATIENT_INFO_IN_PROGRESS"
export const UPDATE_PATIENT_INFO_SUCCESS="UPDATE_PATIENT_INFO_SUCCESS"
export const UPDATE_PATIENT_INFO_FAILED="UPDATE_PATIENT_INFO_FAILED"
export const CLEAR_UPDATE_PATIENT_INFO_STATUS="CLEAR_UPDATE_PATIENT_INFO_STATUS"
export const PATCH_PATIENT_INFO="PATCH_PATIENT_INFO"

export const FETCH_PATIENT_SUMMARY="FETCH_PATIENT_SUMMARY"
export const FETCH_PATIENT_SUMMARY_IN_PROGRESS="FETCH_PATIENT_SUMMARY_IN_PROGRESS"
export const FETCH_PATIENT_SUMMARY_SUCCESS="FETCH_PATIENT_SUMMARY_SUCCESS"
export const FETCH_PATIENT_SUMMARY_FAILED="FETCH_PATIENT_SUMMARY_FAILED"

export const CLEAR_ERROR_UPDATE_PATIENT_INFO = "CLEAR_ERROR_UPDATE_PATIENT_INFO"

export const FETCH_PATIENT_LOCATION="FETCH_PATIENT_LOCATION"
export const FETCH_PATIENT_LOCATION_IN_PROGRESS="FETCH_PATIENT_LOCATION_IN_PROGRESS"
export const FETCH_PATIENT_LOCATION_SUCCESS="FETCH_PATIENT_LOCATION_SUCCESS"
export const FETCH_PATIENT_LOCATION_FAILED="FETCH_PATIENT_LOCATION_FAILED"

export const UPDATE_PATIENT_STATUS_FOR_DISCHARGE="UPDATE_PATIENT_STATUS_FOR_DISCHARGE"

export const SEARCH_DISCHARGED_PATIENTS="SEARCH_DISCHARGED_PATIENTS"
export const SEARCH_DISCHARGED_PATIENTS_IN_PROGRESS="SEARCH_DISCHARGED_PATIENTS_IN_PROGRESS"
export const SEARCH_DISCHARGED_PATIENTS_SUCCESS="SEARCH_DISCHARGED_PATIENTS_SUCCESS"
export const SEARCH_DISCHARGED_PATIENTS_FAILED="SEARCH_DISCHARGED_PATIENTS_FAILED"

export const FETCH_SOURCE_PATIENT_DETAILS="FETCH_SOURCE_PATIENT_DETAILS"
export const FETCH_SOURCE_PATIENT_DETAILS_IN_PROGRESS="FETCH_SOURCE_PATIENT_DETAILS_IN_PROGRESS"
export const FETCH_SOURCE_PATIENT_DETAILS_SUCCESS="FETCH_SOURCE_PATIENT_DETAILS_SUCCESS"
export const FETCH_SOURCE_PATIENT_DETAILS_FAILED="FETCH_SOURCE_PATIENT_DETAILS_FAILED"

export type PatientFilters = {
    all?: boolean
    pendingIntake?: boolean
    pendingTreatment?: boolean
    socialWorkerId?:string
}

export const searchPatientsInProgress = () : AnyAction => {
    return {type: SEARCH_PATIENTS_IN_PROGRESS }
}


export const searchPatients = (currentPage: number, recordsPerPage: number, searchText: string, filters?: PatientFilters) : AnyAction => {
    return {type: SEARCH_PATIENTS, payload: {pageNumber: currentPage - 1, recordsPerPage, searchText, filters: filters}}
}
export const searchingPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: SEARCH_PATIENTS_FAILED, payload}
}
export const searchedPatients = (patients: ITherapistPatient[], count: number) : AnyAction => {
    return {type: SEARCH_PATIENTS_SUCCESS, payload: {patients, count: count}}
}

export const fetchPatients = () : AnyAction => {
    return {type: FETCH_PATIENTS}
}
export const fetchingPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PATIENTS_FAILED, payload}
}
export const fetchedPatients = (patients: ITherapistPatient[]) : AnyAction => {
    return {type: FETCH_PATIENTS_SUCCESS, payload: {patients}}
}

export const fetchPatient = (patientId: string): AnyAction => {
    return {type: FETCH_PATIENT, payload: {patientId}}
}
export const fetchPracticePatient = (sourcePatientId: string ): AnyAction => {
    return {type: FETCH_PRACTICE_PATIENT, payload: {sourcePatientId}}
}
export const fetchingPatientFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PATIENT_FAILED, payload}
}
export const fetchedPatient = (patient: IProspect) : AnyAction => {
    return {type: FETCH_PATIENT_SUCCESS, payload: {patient}}
}
export const clearPatientAndSourcePatient = (): AnyAction => {
    return {type: CLEAR_PATIENT_AND_SOURCE_PATIENT}
}

export const fetchPatientWithSummary = (patientId: string, practiceId: string, sourcePatientId: string): AnyAction => {
    return {type: FETCH_PATIENT_WITH_SUMMARY, payload: {patientId, practiceId, sourcePatientId}}
}
export const fetchingPatientWithSummaryFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PATIENT_WITH_SUMMARY_FAILED, payload}
}
export const fetchedPatientWithSummary = (patient: IProspect) : AnyAction => {
    return {type: FETCH_PATIENT_WITH_SUMMARY_SUCCESS, payload: {patient}}
}

export const fetchTherapistPatientBySourcePatient = (sourcePatientId: string ): AnyAction => {
    return {type: FETCH_PATIENT_BY_SOURCE_PATIENT_ID, payload: {sourcePatientId}}
}
export const fetchingPatientBySourceInProgress = () : AnyAction => {
    return {type: FETCH_PATIENT_BY_SOURCE_PATIENT_ID_IN_PROGRESS}
}
export const fetchingPatientBySourceFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PATIENT_BY_SOURCE_PATIENT_ID_FAILED, payload}
}
export const fetchedPatientBySource = (patient: IProspect) : AnyAction => {
    return {type: FETCH_PATIENT_BY_SOURCE_PATIENT_ID_SUCCESS, payload: {patient}}
}
export const fetchTreatmentNotes = (patientId: string, notesId?: string ): AnyAction => {
    return {type: FETCH_TREATMENT_NOTES, payload: {patientId, notesId}}
}
export const fetchingTreatmentNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_TREATMENT_NOTES_FAILED, payload}
}
export const fetchedTreatmentNotes = (treatmentNotes: TreatmentPlanNotes, treatmentPlanCount: number, treatmentId: string, notesStatus: NotesStatus) : AnyAction => {
    return { type: FETCH_TREATMENT_NOTES_SUCCESS, payload: { treatmentNotes, treatmentPlanCount, treatmentId, notesStatus } }
}

export const fetchProgressNotes = (patientId: string, notesId?: string): AnyAction => {
    return {type: FETCH_PROGRESS_NOTES, payload: {patientId, notesId}}
}
export const fetchingProgressNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PROGRESS_NOTES_FAILED, payload}
}
export const fetchedProgressNotes = (progressNotes: { [key: string]: [value: string[] | string | number | Object] }, progressNotesId?: string, notesStatus?: NotesStatus) : AnyAction => {
    return { type: FETCH_PROGRESS_NOTES_SUCCESS, payload: { progressNotes, progressNotesId, notesStatus } }
}

export const saveTreatmentNotes = (patientId: string, notes: { treatmentNotes: { [key: string]: [value: any] } }, noteId: string | null): AnyAction => {
    return { type: SAVE_TREATMENT_NOTES, payload: { patientId, notes, noteId } }
}
export const savingTreatmentNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: SAVE_TREATMENT_NOTES_FAILED, payload}
}
export const savedTreatmentNotes = (keys: string[], isTreatmentNotesSaved: boolean) : AnyAction => {
    return { type: SAVE_TREATMENT_NOTES_SUCCESS, payload: { keys, isTreatmentNotesSaved } }
}

export const saveProgressNotes = (patientId: string, notes: { progressNotes: { [key: string]: [value: any] }  }, noteId: string | null): AnyAction => {
    return { type: SAVE_PROGRESS_NOTES, payload: { patientId, notes, noteId } }
}
export const savingProgressNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: SAVE_PROGRESS_NOTES_FAILED, payload}
}
export const savedProgressNotes = (keys: string[], isProgressNotesSaved: boolean) : AnyAction => {
    return { type: SAVE_PROGRESS_NOTES_SUCCESS, payload: { keys, isProgressNotesSaved } }
}

export const reviewAndSubmitTreatmentNotes = (patientId: string, screeners: string[], sourcePatientId: string, treatmentId: string, practiceId: string ): AnyAction => {
    return { type: REVIEW_AND_SUBMIT_TREATMENT_PLAN, payload: { patientId, screeners, sourcePatientId, treatmentId, practiceId } }
}
export const reviewAndSubmittingTreatmentNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: REVIEW_AND_SUBMIT_TREATMENT_PLAN_FAILED, payload}
}
export const reviewAndSubmittedTreatmentNotes = (isTreatmentNotesReviewed: boolean) : AnyAction => {
    return { type: REVIEW_AND_SUBMIT_TREATMENT_PLAN_SUCCESS, payload: { isTreatmentNotesReviewed } }
}

export const fetchSafetyPlanNotes = (patientId: string ): AnyAction => {
    return {type: FETCH_SAFETY_PLAN_NOTES, payload: {patientId}}
}
export const fetchingSafetyPlanNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_SAFETY_PLAN_NOTES_FAILED, payload}
}
export const fetchedSafetyPlanNotes = (safetyPlanNotes: { [key: string]: [value: any] }, notesStatus: NotesStatus) : AnyAction => {
    return { type: FETCH_SAFETY_PLAN_NOTES_SUCCESS, payload: { safetyPlanNotes, notesStatus } }
}

export const saveSafetyPlanNotes = (patientId: string, notes: { safetyPlanNotes: { [key: string]: [value: any] } }): AnyAction => {
    return { type: SAVE_SAFETY_PLAN_NOTES, payload: { patientId, notes } }
}
export const savingSafetyPlanNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: SAVE_SAFETY_PLAN_NOTES_FAILED, payload}
}
export const savedSafetyPlanNotes = (keys: string[], isSafetyPlanNotesSaved: boolean) : AnyAction => {
    return { type: SAVE_SAFETY_PLAN_NOTES_SUCCESS, payload: { isSafetyPlanNotesSaved } }
}

export const fetchPatientJourney = (patientId: string, sourcePatientId: string ): AnyAction => {
    return { type: FETCH_PATIENT_JOURNEY, payload: { patientId, sourcePatientId } }
}
export const fetchingPatientJourneyFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_PATIENT_JOURNEY_FAILED, payload}
}
export const fetchedPatientJourney = (patientJourney: PatientJourney[]): AnyAction => {
    return { type: FETCH_PATIENT_JOURNEY_SUCCESS, payload: { patientJourney } }
}

export const fetchSessionInsights = (patientId: string, frequency: number ): AnyAction => {
    return { type: FETCH_SESSION_INSIGHTS, payload: { patientId, frequency } }
}
export const fetchingSessionInsightsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_SESSION_INSIGHTS_FAILED, payload}
}
export const fetchedSessionInsights = (sessionInsights: PatientSessionInsights): AnyAction => {
    return { type: FETCH_SESSION_INSIGHTS_SUCCESS, payload: { sessionInsights } }
}

export const fetchDailyCheckinInsights = (patientId: string, frequency: number, sourcePatientId: string ): AnyAction => {
    return { type: FETCH_DAILY_CHECKIN_INSIGHTS, payload: { patientId, frequency, sourcePatientId } }
}
export const fetchingDailyCheckinInsightsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_DAILY_CHECKIN_INSIGHTS_FAILED, payload}
}
export const fetchedDailyCheckinInsights = (dailyCheckinInsights: PatientDailyCheckinInsights): AnyAction => {
    return { type: FETCH_DAILY_CHECKIN_INSIGHTS_SUCCESS, payload: { dailyCheckinInsights } }
}

export const fetchSWScreenersInsights = (patientId: string, sourcePatientId: string, shouldFetchPreviousScreeners: boolean ): AnyAction => {
    return { type: FETCH_SW_SCREENERS_INSIGHTS, payload: { patientId, sourcePatientId, shouldFetchPreviousScreeners } }
}
export const fetchingSWScreenersInsightsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_SW_SCREENERS_INSIGHTS_FAILED, payload}
}
export const fetchedSWScreenersInsights = (response: SWScreenerInsights[]): AnyAction => {
    return { type: FETCH_SW_SCREENERS_INSIGHTS_SUCCESS, payload: {swScreenerInsights: response} }
}
export const clearSWScreenerInsights = (): AnyAction => {
    return { type: CLEAR_SW_SCREENER_INSIGHTS }
}

export const setTreamentPlanScreeners = (screeners: string[] ): AnyAction => {
    return { type: SET_TREATMENT_PLAN_SCREENERS, payload: { screeners } }
}
export const updateLatestNotesStatus = (notesStatus: NotesStatus): AnyAction => {
    return { type: UPDATE_LATEST_NOTES_STATUS, payload: { notesStatus } }
}
export const clearToastNotesStatus = (): AnyAction => {
    return { type: CLEAR_TOAST_NOTES_STATUS }
}

export const saveCatchupNotes = (patientId: string, notes: CatchupNotes, notesId: string | null): AnyAction => {
    return { type: SAVE_CATCHUP_NOTES, payload: { patientId, notes, notesId } }
}
export const savingCatchupNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: SAVE_CATCHUP_NOTES_FAILED, payload }
}
export const savedCatchupNotes = (keys: string[], catchupNotesResponse: CatchupNotesResponse): AnyAction => {
    return { type: SAVE_CATCHUP_NOTES_SUCCESS, payload: {keys, catchupNotesResponse} }
}
export const fetchCatchupNotes = (patientId: string, notesId?: string): AnyAction => {
    return { type: FETCH_CATCHUP_NOTES, payload: { patientId, notesId } }
}
export const fetchingCatchupNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_CATCHUP_NOTES_FAILED, payload }
}
export const fetchedCatchupNotes = (catchupNotesResponse: CatchupNotesResponse): AnyAction => {
    return { type: FETCH_CATCHUP_NOTES_SUCCESS, payload: catchupNotesResponse }
}

export const savePsychiatristNotes = (patientId: string, notes: PsychiatristNotes, noteId: string | null): AnyAction => {
    return { type: SAVE_PSYCHIATRIST_NOTES, payload: { patientId, notes, noteId } }
}
export const savingPsychiatristNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: SAVE_PSYCHIATRIST_NOTES_FAILED, payload }
}
export const savedPsychiatristNotes = (keys: string[], psychiatristNotesResponse: PsychiatristNotesResponse): AnyAction => {
    return { type: SAVE_PSYCHIATRIST_NOTES_SUCCESS, payload: {keys, psychiatristNotesResponse} }
}
export const fetchPsychiatristNotes = (patientId: string, notesId?: string): AnyAction => {
    return { type: FETCH_PSYCHIATRIST_NOTES, payload: { patientId, notesId } }
}
export const fetchingPsychiatristNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PSYCHIATRIST_NOTES_FAILED, payload }
}
export const fetchedPsychiatristNotes = (psychiatristNotesResponse: PsychiatristNotesResponse): AnyAction => {
    return { type: FETCH_PSYCHIATRIST_NOTES_SUCCESS, payload: psychiatristNotesResponse }
}

export const fetchAssignedScreeners = (patientId: string, treatmentId: string, providerType: ScreenerProviderType): AnyAction => {
    return { type: FETCH_ASSIGNED_SCREENERS, payload: {patientId, treatmentId, providerType}}
}
export const fetchedAssignedScreeners = (patientAssignedScreeners: IAssignedScreeners[]): AnyAction => {
    return { type: FETCH_ASSIGNED_SCREENERS_SUCCESS, payload: {patientAssignedScreeners}}
}
export const fetchingAssignedScreenersFailed = (payload: {error: string}): AnyAction => {
    return { type: FETCH_ASSIGNED_SCREENERS_FAILED, payload}
}

export const fetchScreenerQAndA = (patientId: string, userScreenerId: string, screenerConditionId: string|null): AnyAction => {
    return { type: FETCH_SCREENER_Q_AND_A, payload: {patientId, userScreenerId, screenerConditionId}}
}
export const fetchedScreenerQAndA = (screenerQAndA: CurrentQuestion): AnyAction => {
    return { type: FETCH_SCREENER_Q_AND_A_SUCCESS, payload: {screenerQAndA}}
}
export const fetchingScreenerQAndAFailed = (payload: {error: string}): AnyAction => {
    return { type: FETCH_SCREENER_Q_AND_A_FAILED, payload}
}

export const saveScreenerAnswer = (request: SWUserScreenerRequest): AnyAction => {
    return { type: SAVE_SCREENER_ANSWER, payload: request}
}
export const savedScreenerAnswer = (nextQuestionLink: string|null): AnyAction => {
    return { type: SAVE_SCREENER_ANSWER_SUCCESS, payload: {nextQuestionLink}}
}
export const savingScreenerAnswerFailed = (payload: {error: string}): AnyAction => {
    return { type: SAVE_SCREENER_ANSWER_FAILED, payload}
}

export const updatePatientInfo = (patientId: string, practiceId: string, patientInfo: Partial<PatientAccount>): AnyAction => {
    return { type: UPDATE_PATIENT_INFO, payload: { patientId, practiceId, patientInfo } }
}
export const updatePatientInfoSuccess = (contactPhoneNumber: string | null | undefined): AnyAction => {
    return { type: UPDATE_PATIENT_INFO_SUCCESS, payload: { contactPhoneNumber } }
}
export const updatePatientInfoFailed = (payload: { error: string }): AnyAction => {
    return { type: UPDATE_PATIENT_INFO_FAILED, payload }
}
export const clearUpdatePatientInfoStatus = (): AnyAction => {
    return { type: CLEAR_UPDATE_PATIENT_INFO_STATUS }
}

export const fetchPatientSummary = (patientId: string): AnyAction => {
    return { type: FETCH_PATIENT_SUMMARY, payload: { patientId } }
}
export const fetchPatientSummarySuccess = (patientSummary: any): AnyAction => {
    return { type: FETCH_PATIENT_SUMMARY_SUCCESS, payload: { patientSummary } }
}
export const fetchPatientSummaryFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_SUMMARY_FAILED, payload }
}

export const fetchPatientLocation = (patientId: string): AnyAction => {
    return { type: FETCH_PATIENT_LOCATION, payload: { patientId } }
}
export const fetchPatientLocationSuccess = (patientLocation: LocationIndexItem): AnyAction => {
    return { type: FETCH_PATIENT_LOCATION_SUCCESS, payload: { patientLocation } }
}
export const fetchPatientLocationFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_LOCATION_FAILED, payload }
}

export const clearScreenerQuestions = (): AnyAction => {
    return {type: CLEAR_SCREENER_QUESTIONS}
}
export const clearErrorForUpdatePatientInfo = (): AnyAction => {
    return { type: CLEAR_ERROR_UPDATE_PATIENT_INFO }
}


export const searchDischargedPatientsInProgress = () : AnyAction => {
    return {type: SEARCH_DISCHARGED_PATIENTS_IN_PROGRESS}
}
export const searchDischargedPatients = (currentPage: number, recordsPerPage: number, searchText: string) : AnyAction => {
    return {type: SEARCH_DISCHARGED_PATIENTS, payload: {currentPage, recordsPerPage, searchText}}
}
export const searchingDischargedPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: SEARCH_DISCHARGED_PATIENTS_FAILED, payload}
}
export const searchedDischargedPatients = (patients: DischargedPatients[], count: number) : AnyAction => {
    return {type: SEARCH_DISCHARGED_PATIENTS_SUCCESS, payload: {patients, count}}
}

export const fetchSourcePatientDetails = (patientId: string) : AnyAction => {
    return {type: FETCH_SOURCE_PATIENT_DETAILS, payload: {patientId}}
}
export const fetchingSourcePatientDetailsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_SOURCE_PATIENT_DETAILS_FAILED, payload}
}
export const fetchedSourcePatientDetails = (sourcePatient: SourcePatient) : AnyAction => {
    return {type: FETCH_SOURCE_PATIENT_DETAILS_SUCCESS, payload: {sourcePatient}}
}