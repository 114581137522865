import {IPatient} from "../../../models";
import {DateTime} from "luxon";
import React from "react";
import {MaskPhoneNumber} from "../../generic/MaskPhoneNumber";
import { useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";

const PatientProfile: React.FC<{ patient: IPatient }> = ({patient}) => {
    const sourcePatient = useSelector((state: StateParams) => state.patients.sourcePatient)
    const provider = sourcePatient?.patientStatuses?.find(patient => patient.provider)?.provider
    const referringPhysician = provider ? `Dr. ${provider.firstName} ${provider.lastName}` : 'N/A'
    const referredOn = sourcePatient?.referredDate ? DateTime.fromISO(sourcePatient?.referredDate, {zone: 'utc'}).toFormat('MM/dd/yyyy') : 'N/A'
    const medicalAssistant = sourcePatient?.patientStatuses && sourcePatient?.patientStatuses[0]?.medicalAssistant
    const referringMA = medicalAssistant ? `${medicalAssistant.firstName} ${medicalAssistant.lastName}` : 'N/A'

    console.log(patient);
    console.log(sourcePatient);

    return (
        <section id={"profile"} className={"w-full bg-sjWhite pl-8 py-4 rounded-lg"}>
            <div id={"profile-name-phone"} className={"w-full flex flex-row items-center justify-between pr-20"}>
                <div className={"flex flex-row mb-6 items-center"}>
                    <div className={"w-auto flex flex-col break-all justify-center"}>
                        <MaskPhoneNumber value={patient.contactPhoneNumber} disabled={true}/>
                        <div className="pt-2.5 mb-1">
                            <p className={"text-xl text-sjText font-semibold capitalize"}>{patient.firstName} {patient.lastName}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`grid grid-flow-col auto-cols-max divide-x space-x-3 gap-x-3 items-stretch m-auto`}>
                <div className={"text-left col-start-1"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Gender</p>
                    <p className={"mt-1.5 text-black text-sm capitalize"}>{`${patient.gender}`}</p>
                </div>
                <div className={"pl-6 text-left col-start-2"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Date of Birth</p>
                    <p className={"mt-1.5 text-black text-sm"}>{DateTime.fromISO(patient.dob.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy')}</p>
                </div>
                <div className={"pl-6 text-left col-start-3"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Zip code</p>
                    <p className={"mt-1.5 text-black text-sm"}>{patient.zipCode}</p>
                </div>
                {medicalAssistant && 
                <div className={"pl-6 text-left col-start-4"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Referring MA</p>
                <p className={"mt-1.5 text-black text-sm"}>{referringMA}</p>
                </div>
                }
                <div className={"pl-6 text-left col-start-5"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Referring Physician</p>
                    <p className={"mt-1.5 text-black text-sm"}>{referringPhysician}</p>
                </div>
                <div className={"pl-6 text-left col-start-6"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Date of Referral </p>
                    <p className={"mt-1.5 text-black text-sm"}>{referredOn}</p>
                </div>
            </div>
        </section>)
}


export default PatientProfile
