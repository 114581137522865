import React, { useEffect, useRef, useState } from "react";
import { IPatient, ISummary } from "../../../models";
import { Tab } from '@headlessui/react'
import { classNames } from "../../../shared/Utils";
import * as _ from 'lodash'
import ProspectResultContent from "./ProspectResultContent";
import { SLUG_TO_TAB_MAP, TYPE_SLUG } from "../../screeners/ScreenerResults";
import { StateParams } from "../../../store/reducers";
import { useSelector } from "react-redux";

const SCREENER_ORDER = [
    "psc-y",
    "psc-6to12",
    "fp-psc-6to12",
    "fp-psc-y",
    "fp-two-month",
    "fp-four-month",
    "fp-six-month",
    "fp-nine-month",
    "fp-twelve-month",
    "fp-fifteen-month",
    "fp-eighteen-month",
    "fp-twentyfour-month",
    "fp-thirty-month",
    "fp-thirtysix-month",
    "fp-fortyeight-month",
    "fp-sixty-month",
    "ace-above18",
    "ace-12to18",
    "ace-below6",
    "ace-6to12",
    "fp-ace-12to18",
    "fp-ace-6to12",
    "fp-ace-above18",
    "fp-ace-below6",
    "fp-m-chat-r",
    "slesq-above18",
    "fp-slesq-above18",
    "slesq-50to150",
    "anxiety-SCARED",
    "anxiety",
    "anxiety-50to150",
    "fp-anxiety",
    "fp-anxiety-SCARED",
    "fp-anxiety-above18",
    "depression-MFQ",
    "depression",
    "fp-depression-MFQ",
    "phq-9",
    "phq-9-50to150",
    "fp-phq-9",
    "fp-phq-9-above18",
    "suicidal",
    "fp-suicidal",
    "fp-phq9-suicidal",
    "adhd-6to12",
    "adhd-12to25",
    "fp-adhd-12to18",
    "fp-adhd-6to12",
    "fp-drug-above18",
    "fp-sdoh-above18",
    "fp-alcohol-above18",
    "fp-ptsd-above18",
]

const ProspectResults: React.FC<{ patient: IPatient, summary: ISummary }> = ({ patient, summary }) => {
    const patientScreeners = _.chain(patient.screeners).keyBy("slug").value();
    const screenerTabs = _.chain(patientScreeners)
        .keys()
        .orderBy(slug => SCREENER_ORDER.indexOf(slug))
        .value()
        .filter(slug => {
            return !['fp-questionnaire-above18', 'fp-questionnaire-above18-v2'].includes(slug);
        });

    const staticTabs = ["Summary"]
    const { role } = useSelector((state: StateParams) => state.account)
    const tabs = role === 'Medical_Assistant' ? [...staticTabs] : [...staticTabs, ...screenerTabs]
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedTab, setSelectedTab] = useState<string>()
    const summaryComponentRef = useRef(null);

    useEffect(() => {
        setSelectedTab(tabs[selectedIndex])
    }, [selectedIndex])


    return (
        <div className="w-full mt-2 h-[50vh]">
            <Tab.Group onChange={setSelectedIndex}>
                <Tab.List className="w-full flex border-b border-solid border-b-sjGrey-200">
                    {tabs.map((resultTab) => (
                        <Tab
                            key={resultTab}
                            className={({ selected }) => {
                                return classNames(
                                    'mr-5 py-2.5 text-sm font-medium',
                                    'ring-offset-2 focus:outline-none',
                                    selected
                                        ? 'text-sjOrange border-b border-solid border-b-sjOrange'
                                        : 'text-sjGrey hover:text-sjOrange'
                                )
                            }}
                        >
                            {SLUG_TO_TAB_MAP[resultTab as TYPE_SLUG] || resultTab}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>
            {selectedTab && <ProspectResultContent patient={patient}
                summary={summary}
                screeners={patientScreeners}
                selectedTab={selectedTab}
                isStaticTab={staticTabs.includes(selectedTab)}
                summaryComponentRef={summaryComponentRef} updateSelectedIndex={setSelectedIndex}
            />}
        </div>
    )
}

export default ProspectResults