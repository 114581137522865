import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ConfirmationModal from '../clinical-notes/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchICDCodes } from '../../store/actions/physician/patients.action';
import { IcdCodesRequest } from '../../models';
import { StateParams } from '../../store/reducers';

interface Props {
    value: IcdCodesRequest | null
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
}

const IcdCodesModal: React.FC<Props> = ({ value, isModalOpen, setIsModalOpen }) => {
    const dispatch = useDispatch();
    const { icdCodes } = useSelector((state: StateParams) => state.physicianPatients);

    useEffect(() => {
        if(value?.cptCode && value?.userId && value?.screeningDate && value?.units) {
            dispatch(fetchICDCodes({
                cptCode: value.cptCode,
                userId: value.userId,
                screeningDate: value.screeningDate,
                units: value.units
            }));
        }
    }, [value?.userId, value?.cptCode])

    if(!value) {
        return null;
    }

    return (
        <div>
            
            <ConfirmationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} key={'IcdCodesModal'}
                modalClassNames={'!p-1 !w-2/6'}>
                <p className='text-left text-base text-sjHeader font-normal'>{value.patientName} ({value.dob})</p>
                <div className='divide-y'>
                    <div><p className='mt-3 text-left text-sm text-sjHeader font-normal'>CPT Code: {value.cptCode}</p></div>
                    <div></div>
                </div>
                <p className='mt-4 text-left text-sm text-sjHeader font-normal'>The respective ICD Codes are - </p>
                {icdCodes ? <p className='mt-3 text-left text-sm text-sjGray font-normal'>{icdCodes}</p> : <p className='mt-3 text-left text-sm text-sjGray font-normal'>No ICD Codes found.</p>}
            </ConfirmationModal>
        </div>
    )
}

export default IcdCodesModal;