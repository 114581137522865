import React, {useCallback, useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import "./PhysicianDashboardScreen.scss";
import PhysicianDashboardStatisticsComponent from "./components/PhysicianDashboardStatisticsComponent";
import PhysicianDashboardLocationBasedScreenersComponent
    from "./components/PhysicianDashboardLocationBasedScreenersComponent";
import PhysicianDashboardScreenerCompletionRateComponent
    from "./components/PhysicianDashboardScreenerCompletionRateComponent";
import {DateTime} from "luxon";
import { fetchPatientsScreenerCompletionRate, fetchPatientsSummary, fetchPatientsSummaryByLocation } from "../../../store/actions/physician/dashboard.action";
import PhysicianDashboardDateFilterComponent from "./components/PhysicianDashboardDateFilterComponent";

import { AggregatePatientSummaryByLocation, ScreenerCompletionRate } from "../../../models";

export type DashboardFilters = {
    startDate: string,
    endDate: string
}

const PhysicianDashboardScreen = () => {
    const dispatch = useDispatch();
    const {
        firstName, lastName
    } = useSelector((state: StateParams) => state.account);
    const {patientsSummaryByLocation, patientsScreenerCompletionRate, fetchPatientsSummaryByLocationInProgress, fetchPatientsScreenerCompletionRateInProgress} = useSelector((state: StateParams) => state.patientsDashboard)
    const [locationBasedPatientSummary, setLocationBasedPatientSummary] = useState<AggregatePatientSummaryByLocation[]>([]);
    const [completionRateForPatientScreeners, setCompletionRateForPatientScreeners] = useState<ScreenerCompletionRate[]>([]);
    const initialStartDate = DateTime.local().minus({days: 7}).toFormat('yyyy-MM-dd');
    const initialEndDate = DateTime.local().endOf('day').toFormat('yyyy-MM-dd');
    const [filters, setFilters] = useState<DashboardFilters>({
        startDate: initialStartDate,
        endDate: initialEndDate
    });

    useEffect(() => {
        console.log(filters.startDate, filters.endDate)
        batch(() => {
            dispatch(fetchPatientsSummary(filters))
            dispatch(fetchPatientsSummaryByLocation(filters))
            dispatch(fetchPatientsScreenerCompletionRate(filters))
        })
    },[filters])

    useEffect(() => {
        if (patientsSummaryByLocation) {
            setLocationBasedPatientSummary(patientsSummaryByLocation)
        }
    }, [patientsSummaryByLocation])

    useEffect(() => {
        if (patientsScreenerCompletionRate) {
            setCompletionRateForPatientScreeners(patientsScreenerCompletionRate)
        }
    }, [patientsScreenerCompletionRate])

    return (
        <div className="flex-1 physician-dashboard-screen bg-[#F7F8FA]">
            <div className="physician-dashboard-screen-header">
                <h1 className="physician-dashboard-screen-title"> Hello Dr. {firstName} {lastName} 👋</h1>
                <div className="physician-dashboard-screen-actions">
                    <PhysicianDashboardDateFilterComponent
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onFilterChange={(startDate, endDate) => setFilters({startDate, endDate})}
                    />
                </div>
            </div>
            <div className="physician-dashboard-screen-content">
                <PhysicianDashboardStatisticsComponent/>
                <div className="physician-dashboard-screen-cards">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {locationBasedPatientSummary && <PhysicianDashboardLocationBasedScreenersComponent patientSummary={locationBasedPatientSummary} searchInProgress={fetchPatientsSummaryByLocationInProgress}/> }
                        {completionRateForPatientScreeners && <PhysicianDashboardScreenerCompletionRateComponent patientSummary={completionRateForPatientScreeners} searchInProgress={fetchPatientsScreenerCompletionRateInProgress}/> }
                        <div className="grid grid-cols-subgrid col-span-2">
                            {/* <PhysicianDashboardMAPerformanceComponent/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhysicianDashboardScreen;
