import React from "react";
import {IProspect, ISummary} from "../../models";
import ProspectProfile from "../prospects/ProspectProfile";
import ScreenerResults from "../screeners/ScreenerResults";

interface PatientProps {
    patient: IProspect
    summary: ISummary
}

const Patient : React.FC<PatientProps> = ({patient, summary}) => {

    return <>
        <ProspectProfile prospect={patient}/>
        <ScreenerResults prospect={patient} summary={summary}/>
    </>
}

export default Patient
