import React, {useCallback, useEffect, useState} from "react";
import {IPatientInCall} from "../../models";
import {canEditAndMakeCallsToPatient} from "../clinical-notes/accessControl";
import {PageButtonV2} from "../generic/Buttons";
import {ACTIVE_CALL_STATUSES, requestCall} from "../../store/actions/counsellor/calls.action";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import PhoneIcon from "../../assets/images/calls/phone.svg"
import {fetchPracticeDetails} from "../../store/actions/counsellor/practice.action";
import { Tooltip } from 'react-tooltip'
import { fetchPatientLocation } from "../../store/actions/counsellor/patients.action";

const VoipPhoneNumber: React.FC<{patient: IPatientInCall, onCallInitated?: () => void}> = ({patient, onCallInitated}) => {
    const callStatus = useSelector((state: StateParams) => state.calls.callStatus)
    const role = useSelector((state: StateParams) => state.account.role)
    const {id : practiceId, isPhoneVerified} = useSelector((state: StateParams) => state.practice)
    const patientLocation = useSelector((state: StateParams) => state.patients.patientLocation)
    const [validToMakeCall, setValidToMakeCall] = useState<boolean | undefined>(undefined)
    const dispatch = useDispatch()

    const initiateVoipCall = useCallback(() => {
        if(validToMakeCall && patient) {
            dispatch(requestCall(patient))
            onCallInitated?.()
        }
    }, [validToMakeCall, patient])

    useEffect(() => {
        dispatch(fetchPracticeDetails({practiceId: patient.referredByPracticeId}))
        dispatch(fetchPatientLocation(patient.sourcePatientId))
    }, [])


    useEffect(() => {
        if(patient.referredByPracticeId === practiceId) {
            setValidToMakeCall(patientLocation?.isPhoneVerified === true)
        }
    }, [patient, practiceId, patientLocation?.isPhoneVerified])


    function renderToolTipForInvalidPhoneNumber() {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}} id="phone-number-tooltip" >
            <div className={"w-[200px]"}>
                <span>
                    The practice phone number is not verified.
                </span>
            </div>
        </Tooltip>
    }

    function renderEditAndCallButtons() {
        return (
            <div className={""}>
                {canEditAndMakeCallsToPatient(role) &&
                    <PageButtonV2
                        className={`${ACTIVE_CALL_STATUSES.includes(callStatus) || validToMakeCall === false ? "!bg-sjGrey" : "!bg-sjOrange"} !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm`}
                        disabled={ACTIVE_CALL_STATUSES.includes(callStatus) || validToMakeCall === false}
                        data-tooltip-id="phone-number-tooltip"
                        onClick={initiateVoipCall}
                    >

                        <div className={"flex flex-row gap-x-2 items-center"}>
                            <span>Call Patient</span>
                            <img src={PhoneIcon} width={14} height={14} className={"hover:cursor-pointer"}/>
                        </div>

                    </PageButtonV2>
                }
                {validToMakeCall
                    ? null
                    : renderToolTipForInvalidPhoneNumber()
                }
            </div>
        )
    }

    return <>
        <div className={"flex flex-col"}>
            { renderEditAndCallButtons() }
        </div>
    </>

}

export default VoipPhoneNumber