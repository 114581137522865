import React, {useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateParams} from '../../../store/reducers'
import TableFilterPaginationV2 from '../../../components/generic/TableFilterPaginationV2'
import Select from "react-select";
import PaginationV2 from "../../../components/generic/PaginationV2";
import ScreeningPendingPatientListComponent from "./ScreenerPendingPatientsListComponent";
import {fetchScreenerPendingPatients} from "../../../store/actions/counsellor/non-physician-compliance.action";
import BackIcon from "../../../assets/images/common/back.svg";
import {PreferredLanguageFilterOptions} from "../../../services/common.service";
import {RoutePaths} from "../../../shared/Utils";

const ScreenerPendingPatientsScreen = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [backLink, setBackLink] = useState<string>('');

    const {
        isScreenerPendingPatientsInProgress,
        screenerPendingPatientList,
        screenerPendingPatientsCount,
    } = useSelector((state: StateParams) => state.nonPhysicianCompliance);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [preferredLanguage, setPreferredLanguage] = useState<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);

    useEffect(() => {
        // const pathComponents = location.pathname.split("/");
        // if (pathComponents.includes('coordinator')) {
        //     setBackLink(`${RoutePaths.careCoordinator.compliance.root}`);
        // } else {
            setBackLink(`${RoutePaths.collaborativeCare.compliance.root}`);
        // }
    }, [location]);

    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")))
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams])

    useEffect(() => {
        getUsers();
    }, [pageNumber, recordsPerPage, searchText, preferredLanguage]);

    const getUsers = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText || preferredLanguage) {
            dispatch(fetchScreenerPendingPatients({pageNumber, recordsPerPage, searchText, preferredLanguage}))
        }
    }, [pageNumber, recordsPerPage, searchText, preferredLanguage]);

    const renderFilters = useCallback(() => {
        return <div className='flex flex-row gap-3'>
            <Select
                className={"w-48"}
                options={PreferredLanguageFilterOptions}
                placeholder={"Language"}
                isClearable={true}
                isSearchable={false}
                onChange={(selectedOption: any) => {
                    setPageNumber(1);
                    setPreferredLanguage(selectedOption?.value);
                }}
            />
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                totalRows={screenerPendingPatientsCount || 0}
                currentPageHandler={setPageNumber}
            />
        </div>
    }, [screenerPendingPatientsCount, searchText, pageNumber]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            <div>
                <div className="flex flex-row justify-between items-center">
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Link to={backLink}>
                            <img src={BackIcon} width={24} height={24} alt="Go Back"
                                 className={"hover:cursor-pointer"}/>
                        </Link>
                        <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                            <span
                                className="text-xl"> Pending Monthly Screeners ({screenerPendingPatientsCount || 0})  </span>
                        </div>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        {renderFilters()}
                    </div>
                </div>
                <div className="mt-4 relative">
                    <ScreeningPendingPatientListComponent searchInProgress={isScreenerPendingPatientsInProgress}
                                                          patients={screenerPendingPatientList}
                                                          currentPageHandler={setPageNumber}/>
                    {screenerPendingPatientList && screenerPendingPatientList.length > 0 &&
                        <PaginationV2
                            totalRows={screenerPendingPatientsCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ScreenerPendingPatientsScreen;
