import React from "react";
import {DateTime} from 'luxon'
import {IProspect, ProspectReferralNotes, ReferralNoteDisplayMetadata, ReferralNotesToDisplayMap} from "../../models";
import * as _ from 'lodash';

const ReferDetailsV2: React.FC<{patient: IProspect}> = ({patient}) => {
    const referredOn = DateTime.fromISO(patient.createdAt.toString(), {zone: 'utc'}).toFormat("MM/dd/yyyy")
    const referralDetails = patient.referralDetails

    const sections = _.keys(referralDetails)
        .filter(section => !["summary", "screeners"].includes(section))
        .map((section) => {
            return {key: section, displayMetadata: ReferralNotesToDisplayMap[section as keyof ProspectReferralNotes] }
        })

    function renderMultiSelectOptions(key: keyof ProspectReferralNotes, displayMetadata: ReferralNoteDisplayMetadata) {
        const referralNoteSelections = referralDetails[key] as { selected: string[], other?: string }
        if(!referralNoteSelections) {
            return null
        }
        const selectedOptions = referralNoteSelections.selected
        const other = referralNoteSelections.other
        if(selectedOptions.length === 0 && !other) {
            return null
        }
        return <section id = {"refer-metadata"} className = {"w-full mt-2 bg-sjWhite pl-2 py-4 rounded-lg"} >
            <div className = {`grid grid-flow-col auto-cols-max divide-x space-x-3 gap-x-3 items-stretch m-auto` }>
                <div className = {"pl-2 text-left col-start-3"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>{displayMetadata.label}</p>
                        {
                            selectedOptions.map(option => {
                                return <p className={"mt-1.5 text-black text-sm"}>{option}</p>
                            })
                        }
                        { other ? <p className={"mt-1.5 text-black text-sm"}>{other}</p> : null }
                </div>
            </div>
        </section>
    }

    function renderSingleSelectOption(key: keyof ProspectReferralNotes, displayMetadata: ReferralNoteDisplayMetadata) {
        const referralNote = referralDetails[key] as { selected: string, other?: string }
        if(!referralNote) {
            return null
        }
        const selectedOption = referralNote.selected
        const other = referralNote.other
        if(!selectedOption && !other) {
            return null
        }
        return <section id = {"refer-metadata"} className = {"w-full mt-2 bg-sjWhite pl-2 py-4 rounded-lg"} >
            <div className = {`grid grid-flow-col auto-cols-max divide-x space-x-3 gap-x-3 items-stretch m-auto` }>
                <div className = {"pl-2 text-left col-start-3"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>{displayMetadata.label}</p>
                    { selectedOption ? <p className={"mt-1.5 text-black text-sm"}>{selectedOption}</p> : null }
                    { other ? <p className={"mt-1.5 text-black text-sm"}>{other}</p> : null }
                </div>
            </div>
        </section>
    }
    function renderStringNote(key: keyof ProspectReferralNotes, displayMetadata: ReferralNoteDisplayMetadata) {
        const referralNote = referralDetails[key] as string
        if(!referralNote) {
            return null
        }
        return <section id = {"refer-metadata"} className = {"w-full mt-2 bg-sjWhite pl-2 py-4 rounded-lg"} >
            <div className = {`grid grid-flow-col auto-cols-max divide-x space-x-3 gap-x-3 items-stretch m-auto` }>
                <div className = {"pl-2 text-left col-start-3"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>{displayMetadata.label}</p>
                    <p className={"mt-1.5 text-black text-sm"}>{referralNote}</p>
                </div>
            </div>
        </section>
    }

    function renderReferNoteSection(key: string, displayMetadata: ReferralNoteDisplayMetadata) {
        return <div>
            { displayMetadata.type === "multi-select" ? renderMultiSelectOptions(key as keyof ProspectReferralNotes, displayMetadata) : null }
            { displayMetadata.type === "string" ? renderStringNote(key as keyof ProspectReferralNotes, displayMetadata) : null }
            { displayMetadata.type === "single-select" ? renderSingleSelectOption(key as keyof ProspectReferralNotes, displayMetadata) : null }
        </div>
        ;
    }

    return <div>
        <p className={"mt-1.5 text-black text-lg"}>{"Referral Details"}</p>
        {sections.map((section) => {
            return section.displayMetadata ? renderReferNoteSection(section.key, section.displayMetadata) : null
        })}
    </div>
}

export default ReferDetailsV2
