import React from "react";
import { IProspectScreenerResponse, ISummary } from "../../models";
import PSCYResults from "./PSCYResults";
import SecondaryResults from "./SecondaryResults";
import ACEResults from "./ACEResults";
import PSCResults from "./PSCResults";
import AnxietySCAREDResults from "./AnxietySCAREDResults";
import ADHDResults from "./ADHDResults";
import TwoToSixResults from "./TwoToSixResults";
import NineToFifteenResults from "./NineToFifteenResults";
import EighteenToThirtyResults from "./EighteenToThirtyResults";
import ThirtySixToSixtyResults from "./ThirtySixToSixtyResults";

const ScreenerQuestionAnswers: React.FC<{ screenerSlug: string, questionAnswers: IProspectScreenerResponse[], summary: ISummary }> = ({ screenerSlug, questionAnswers, summary }) => {
    const screenerScores = summary

    const ScreenerQuestionAnswerContent: { [key: string]: React.FC<{ screenerSlug: string, backgroundColor: string, screenerScoreMessage: string, questionAnswers: IProspectScreenerResponse[], summary: ISummary }> } = {
        "psc-y": PSCYResults,
        "anxiety": SecondaryResults,
        "depression": SecondaryResults,
        "suicidal": SecondaryResults,
        "ace-below6": ACEResults,
        "ace-above18": ACEResults,
        "ace-12to18": ACEResults,
        "ace-6to12": ACEResults,
        "psc-6to12": PSCResults,
        "depression-MFQ": SecondaryResults,
        "anxiety-SCARED": AnxietySCAREDResults,
        "adhd-6to12": ADHDResults,
        "adhd-12to25": ADHDResults,
        "phq-9": SecondaryResults,
        "phq-9-50to150": SecondaryResults,
        "anxiety-50to150": SecondaryResults,
        "fp-ace-12to18": ACEResults,
        "fp-ace-6to12": ACEResults,
        "fp-ace-above18": ACEResults,
        "fp-ace-below6": ACEResults,
        "fp-adhd-12to18": ADHDResults,
        "fp-adhd-6to12": ADHDResults,
        "fp-anxiety": SecondaryResults,
        "fp-anxiety-SCARED": AnxietySCAREDResults,
        "fp-anxiety-above18": SecondaryResults,
        "fp-depression-MFQ": SecondaryResults,
        "fp-phq-9": SecondaryResults,
        "fp-phq-9-above18": SecondaryResults,
        "fp-psc-6to12": PSCResults,
        "fp-psc-y": PSCYResults,
        "fp-suicidal": SecondaryResults,
        "slesq-above18": ACEResults,
        "slesq-50to150": ACEResults,
        "fp-slesq-above18": ACEResults,
        "fp-two-month": TwoToSixResults,
        "fp-four-month": TwoToSixResults,
        "fp-six-month": TwoToSixResults,
        "fp-nine-month": NineToFifteenResults,
        "fp-twelve-month": NineToFifteenResults,
        "fp-fifteen-month": NineToFifteenResults,
        "fp-eighteen-month": EighteenToThirtyResults,
        "fp-twentyfour-month": EighteenToThirtyResults,
        "fp-thirty-month": EighteenToThirtyResults,
        "fp-thirtysix-month": ThirtySixToSixtyResults,
        "fp-fortyeight-month": ThirtySixToSixtyResults,
        "fp-sixty-month": ThirtySixToSixtyResults,
        "fp-m-chat-r": SecondaryResults,
        "fp-phq9-suicidal": SecondaryResults,
        "fp-drug-above18": SecondaryResults,
        "fp-alcohol-above18": SecondaryResults,
        "fp-ptsd-above18": SecondaryResults,
        "fp-sdoh-above18": SecondaryResults,
    }

    const ResultRenderingComponent = ScreenerQuestionAnswerContent[screenerSlug]
    let screenerScoreMessage = ""
    let backgroundColor = "bg-sjPSCY"
    switch (screenerSlug) {
        case "psc-y":
        case "fp-psc-y":
            screenerScoreMessage = (screenerScores?.pscyFailed || screenerScores?.pscySuicidalPositive ? screenerScores.pscyPositiveMessageText : screenerScores?.pscyNegativeMessageText) || ""
            backgroundColor = "bg-sjPSCY"
            break
        case "anxiety":
        case "anxiety-50to150":
        case "fp-anxiety":
        case "fp-anxiety-above18":
            screenerScoreMessage = (screenerScores?.anxietyPositive ? screenerScores.summaryAnxietyPositiveMessageText : screenerScores?.summaryAnxietyNegativeMessageText) || ""
            backgroundColor = "bg-sjAnxiety"
            break
        case "depression":
            screenerScoreMessage = (screenerScores?.depressionPositive ? screenerScores.summaryDepressionPositiveMessageText : screenerScores?.summaryDepressionNegativeMessageText) || ""
            backgroundColor = "bg-sjDepression"
            break
        case "suicidal":
        case "fp-suicidal":
        case "fp-phq9-suicidal":
            screenerScoreMessage = (screenerScores?.pscySuicidalPositive || screenerScores?.suicidalPositive ? screenerScores.summarySuicidalPositiveMessageText : screenerScores?.summarySuicidalNegativeMessageText) || ""
            backgroundColor = "bg-sjSuicidal"
            break
        case "ace-below6":
        case "ace-above18":
        case "ace-6to12":
        case "ace-12to18":
        case "fp-ace-12to18":
        case "fp-ace-6to12":
        case "fp-ace-above18":
        case "fp-ace-below6":
        case "slesq-above18":
        case "slesq-50to150":
        case "fp-slesq-above18":
            screenerScoreMessage = (screenerScores?.acePositive ? screenerScores.summaryAceLevelMessageText : screenerScores?.summaryAceLevelMessageText) || ""
            backgroundColor = "bg-sjAce"
            break
        case "psc-6to12":
        case "fp-psc-6to12":
            screenerScoreMessage = (screenerScores?.pscScreenerPositive ? screenerScores.summaryPscPositiveMessageText : screenerScores.summaryPscNegativeMessageText) || ""
            backgroundColor = "bg-sjPSCY"
            break
        case "anxiety-SCARED":
        case "fp-anxiety-SCARED":
            screenerScoreMessage = (screenerScores?.anxietySCAREDPositive ? screenerScores.summaryAnxietySCAREDPositiveMessageText : screenerScores?.summaryAnxietySCAREDNegativeMessageText) || ""
            backgroundColor = "bg-sjAnxiety"
            break
        case "depression-MFQ":
        case "fp-depression-MFQ":
            screenerScoreMessage = (screenerScores?.depressionMFQPositive ? screenerScores.summaryDepressionMFQPositiveMessageText : screenerScores?.summaryDepressionMFQNegativeMessageText) || ""
            backgroundColor = "bg-sjDepression"
            break
        case "adhd-6to12":
        case "adhd-12to25":
        case "fp-adhd-12to18":
        case "fp-adhd-6to12":
            screenerScoreMessage = (screenerScores?.adhdInattentivePositive || screenerScores?.adhdHyperactivePositive || screenerScores?.adhdCombinedPositive ? screenerScores.summaryAdhdPositiveMessageText : screenerScores?.summaryAdhdNegativeMessageText) || ""
            backgroundColor = "bg-sjAdhd"
            break
        case "phq-9":
        case "phq-9-50to150":
        case "fp-phq-9":
        case "fp-phq-9-above18":
            screenerScoreMessage = (screenerScores?.phq9Positive ? screenerScores?.summaryPhq9PositiveMessageText : screenerScores?.summaryPhq9NegativeMessageText) || ""
            backgroundColor = "bg-sjDepression"
            break
        case "fp-two-month":
        case "fp-four-month":
        case "fp-six-month":
            screenerScoreMessage = ""
            backgroundColor = "bg-sjPSCY"
            break
        case "fp-nine-month":
        case "fp-twelve-month":
        case "fp-fifteen-month":
            screenerScoreMessage = ""
            backgroundColor = "bg-sjPSCY"
            break
        case "fp-eighteen-month":
        case "fp-twentyfour-month":
        case "fp-thirty-month":
            screenerScoreMessage = ""
            backgroundColor = "bg-sjPSCY"
            break
        case "fp-thirtysix-month":
        case "fp-fortyeight-month":
        case "fp-sixty-month":
            screenerScoreMessage = ""
            backgroundColor = "bg-sjPSCY"
            break
        case "fp-m-chat-r":
            screenerScoreMessage = (screenerScores?.asdPositive ? screenerScores.asdMessageText : screenerScores?.asdMessageText) || ""
            backgroundColor = "bg-sjSuicidal"
            break
        case "fp-drug-above18":
            screenerScoreMessage = (screenerScores?.questionnaireDrugPositive ? screenerScores.questionnaireDrugPositiveMessageText : screenerScores?.questionnaireDrugNegativeMessageText) || ""
            backgroundColor = "bg-sjAdhd"
            break
        case "fp-alcohol-above18":
            screenerScoreMessage = ((screenerScores?.questionnaireAlcoholPositive || screenerScores.questionnaireAlcoholPositiveV2) ? screenerScores.questionnaireAlcoholPositiveMessageText : screenerScores?.questionnaireAlcoholNegativeMessageText) || ""
            backgroundColor = "bg-sjAce"
            break
        case "fp-ptsd-above18":
            screenerScoreMessage = (screenerScores?.questionnairePtsdPositive ? screenerScores.questionnairePtsdPositiveMessageText : screenerScores?.questionnairePtsdNegativeMessageText) || ""
            backgroundColor = "bg-sjDepression"
            break
        case "fp-sdoh-above18":
            screenerScoreMessage = (screenerScores?.sdohScreenerPostive ? screenerScores.sdohPositiveMessageText : screenerScores?.sdohNegativeMessageText) || ""
            backgroundColor = "bg-sjSDOH"
            break
        default:
            screenerScoreMessage = "bg-sjPSCY"
    }
    return <ResultRenderingComponent screenerSlug={screenerSlug} backgroundColor={backgroundColor} screenerScoreMessage={screenerScoreMessage} questionAnswers={questionAnswers} summary={screenerScores} />
}

export default ScreenerQuestionAnswers
