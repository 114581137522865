import {ITherapistPatient} from "../../../models";
import {DateTime} from "luxon";
import {DetailsButtonV2} from "../../generic/DetailsButton";
import {PatientsDataView} from "../common/PatientsDataView";
import { ProfileViewV2 } from "../../generic/ProfileView";

export class CareCoordinatorPatientsDataView implements PatientsDataView {
    private therapistPatients: ITherapistPatient[];

    constructor() {
        this.therapistPatients = []
    }

    setPatients(therapistPatients: ITherapistPatient[]) {
        this.therapistPatients = therapistPatients
    }

    getColumns(): any {
        return () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            { header: "Referring Physician", accessorKey: "physician" },
            { header: "Screening Summary", accessorKey: "summaryLink", cell: DetailsButtonV2 },
            { header: "Profile", accessorKey: "profile", cell: ProfileViewV2 }
        ]
    }

    getData(): any {
        return this.therapistPatients.map((therapistPatient) => {
            const dob = DateTime.fromISO(therapistPatient.patientProspect.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
            const referringPhysician = therapistPatient.patientProspect.referredByPhysicianFirstName ? `Dr. ${therapistPatient.patientProspect.referredByPhysicianFirstName} ${therapistPatient.patientProspect.referredByPhysicianLastName}` : 'N/A'
            return {
                id: therapistPatient.patientProspect.id,
                summaryLink: {url: `${therapistPatient.patientProspect.id}/summary`, practiceId: therapistPatient.patientProspect.referredByPracticeId, sourcePatientId: therapistPatient.patientProspect.sourcePatientId},
                name: `${therapistPatient.patientProspect.lastName}, ${therapistPatient.patientProspect.firstName}`,
                dateOfBirth: dob,
                physician: referringPhysician,
                profile: `${therapistPatient.patientProspect.id}/journey`
            }
        })
    }

}
