import React, { useEffect, useState } from "react";
import { Button } from "./Buttons";
import { downloadPatientResponses, removeDownloadedLink } from "../../store/actions/physician/patients.action";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import axios from "axios";

type Props = {
    value: string
    cptCode?: string
    screeningDate: string
}

export const DownloadScreener: React.FC<Props> = ({ value, cptCode, screeningDate }) => {
    const dispatch = useDispatch()
    const { link, fileName } = useSelector((state: StateParams) => state.physicianPatients)
    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false)

    useEffect(() => {
        if (downloadButtonClicked && link && fileName) {
            setDownloadButtonClicked(false)
            axios({
                url: link,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
            dispatch(removeDownloadedLink())
        }
    }, [downloadButtonClicked, link])

    const downloadPDF = () => {
        setDownloadButtonClicked(true)
        dispatch(downloadPatientResponses(value, {cptCode, screeningDate}))
    }

    return (
        <div>
            <Button className="rounded-l-md border-sjOrange text-sjOrange" onClick={downloadPDF} >
                Download PDF
            </Button>
        </div>
    );
}