import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {CallStatus, IPatientInCall} from "../../../models";
import {requestEndCall} from "../../../store/actions/counsellor/calls.action";
import {DateTime} from "luxon";
import EndPhoneCall from "../../../assets/images/calls/end_phone_call.svg"
import {Transition, TransitionStatus} from 'react-transition-group';
import CSS from 'csstype';
import StopWatch from "../../calls/StopWatch";

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles: Partial<Record<TransitionStatus, CSS.Properties>> = {
    entering: {opacity: 0.5},
    entered: {opacity: 1},
    exiting: {opacity: 1},
    exited: {opacity: 0}
};

type CallMetadata = {
    description: string
    style?: CSS.Properties
}

const GREY = "#5D7285";
const GREEN = "#4DB469";
const LIGHT_GREEN = "#458B53"
const RED = "#F40000";
const ORANGE = "#F76D6D"

const CALL_STATUS_DISPLAY_MAP: { [key in CallStatus]: CallMetadata } = {
    [CallStatus.OFFLINE]: {description: "Offline", style: {backgroundColor: GREY}},
    [CallStatus.REQUESTED]: {description: "Connecting...", style: {backgroundColor: GREY}},
    [CallStatus.INITIATED]: {description: "Connecting...", style: {backgroundColor: GREY}},
    [CallStatus.ACTIVE]: {description: "Connecting...", style: {backgroundColor: GREY}},
    [CallStatus.RINGING]: {description: "On Call", style: {backgroundColor: LIGHT_GREEN}},
    [CallStatus.ON_CALL]: {description: "On Call", style: {backgroundColor: GREEN}},
    [CallStatus.READY]: {description: "Ready", style: {backgroundColor: GREY}},
    [CallStatus.ENDED]: {description: "Ended", style: {backgroundColor: ORANGE}},
    [CallStatus.REJECTED]: {description: "Rejected", style: {backgroundColor: ORANGE}},
    [CallStatus.FAILED_INITIATION]: {description: "Failed initiating", style: {backgroundColor: RED}},
    [CallStatus.END_CALL_REQUESTED]: {description: "Ending call...", style: {backgroundColor: ORANGE}},
    [CallStatus.ERRORED]: {description: "Ending call...", style: {backgroundColor: RED}},
}

const ActiveCallsSection: React.FC = () => {
    const nodeRef = useRef(null);
    const dispatch = useDispatch()
    const {patient, callStatus, errorReason} = useSelector((state: StateParams) => state.calls)
    const [callMetadata, setCallMetadata] = useState<CallMetadata>(CALL_STATUS_DISPLAY_MAP[CallStatus.OFFLINE])
    const [readyForTransition, setReadyForTransition] = useState(false)

    useEffect(() => {
        console.log("ActiveCallSection, CallStatus", callStatus)
        if (callStatus) {
            setCallMetadata(CALL_STATUS_DISPLAY_MAP[callStatus])
            if (callStatus === CallStatus.REQUESTED) {
                setReadyForTransition(true)
            }
        }
    }, [callStatus])

    const onEndCall = () => {
        setReadyForTransition(false)
        setCallMetadata(CALL_STATUS_DISPLAY_MAP[CallStatus.END_CALL_REQUESTED])
        dispatch(requestEndCall())
    }

    useEffect(() => {
        console.log("ActiveCallSection, CallStatus", callStatus)
    }, [callStatus])


    const renderErrorReason = (reason: string) => {
        return <div className={"flex flex-row gap-6 items-center"}>
            <div>
                <span>{reason}</span>
            </div>
        </div>
    }
    const renderCall = (prospectInCall: IPatientInCall) => {
        const name = `${prospectInCall.firstName} ${prospectInCall.lastName}`
        const dateOfBirth = DateTime.fromISO(prospectInCall.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
        const contactPhoneNumber = prospectInCall.phoneNumber
        return <div className={"flex flex-row gap-4 items-center"}>
            <div>
                <span>On going call with - </span>
                <span>{name}</span>
                <span> ( {dateOfBirth}, {contactPhoneNumber} ) </span>
            </div>
            <img src={EndPhoneCall} width={24} height={24} alt="End Call" onClick={onEndCall}
                 className={"hover:cursor-pointer"}/>
            <StopWatch startClock={callStatus === CallStatus.ON_CALL}/>
        </div>
    }

    return <Transition nodeRef={nodeRef} in={readyForTransition} timeout={duration}>
        {state => (
            <div key={"active-call"}
                 ref={nodeRef} style={{...defaultStyle, ...transitionStyles[state], ...callMetadata.style}}
                 id={"call-details"}
                 className={`w-full h-[50px] text-sm text-white flex flex-row justify-center items-center`}>
                {errorReason ? renderErrorReason(errorReason) : null}
                {patient ? renderCall(patient) : null}
            </div>
        )}
    </Transition>
}

export default ActiveCallsSection
