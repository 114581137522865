import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient, getAuthHeaderConfig} from "../../../lib";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    IClinicalNotesComplianceStats, IPendingClinicalNotes,
    IScreenerComplianceStats,
    IScreenerPendingPatients,
    TPendingClinicalNotesFiltersType,
    TPendingClinicalNotesListRequest,
    TPendingClinicalNotesStatsRequest,
    TScreeningPendingPatientFiltersType
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {FETCH_SCREENER_COMPLIANCE_STATS} from "../../actions/counsellor";
import {
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS, FETCH_PENDING_CLINICAL_NOTES,
    FETCH_SCREENER_PENDING_PATIENTS,
    fetchClinicalNotesComplianceStatsFailed,
    fetchClinicalNotesComplianceStatsInProgress, fetchedClinicalNotesComplianceStats, fetchedPendingClinicalNotes,
    fetchedScreenerComplianceStats,
    fetchedScreenerPendingPatients, fetchPendingClinicalNotesFailed, fetchPendingClinicalNotesInProgress,
    fetchScreenerComplianceStatsFailed,
    fetchScreenerComplianceStatsInProgress,
    fetchScreenerPendingPatientsFailed,
    fetchScreenerPendingPatientsInProgress
} from "../../actions/counsellor/non-physician-compliance.action";
import {createQueryString} from "../../../lib/api-helpers";

const apiFetchScreenerComplianceStats = (action: { type: string, authState: AuthState }) => {
    const url = `/compliance/monthly-screener-stats`;
    return apiHttpClient.get<Either<CheckedError, IScreenerComplianceStats>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IScreenerComplianceStats)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e);
            const statusCode = e.response?.status || 500;
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse);
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText);
            return Either.left(new Error(e.message));
        });
}

function* fetchScreenerComplianceStats(action: { type: string, authState: AuthState }) {
    try {
        console.log(`NonPhysicianComplianceSaga: fetchScreenerComplianceStats called with ${JSON.stringify(action)}`)
        // yield put(callInProgress());
        yield put(fetchScreenerComplianceStatsInProgress());
        const apiFetchScreenerComplianceStatsResponse: Either<CheckedError, IScreenerComplianceStats> = yield call(apiFetchScreenerComplianceStats, action)
        if (apiFetchScreenerComplianceStatsResponse.isLeft()) {
            const error = apiFetchScreenerComplianceStatsResponse.left();
            if (error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message);
                yield put(fetchScreenerComplianceStatsFailed({error: error.message}));
            } else {
                yield put(fetchScreenerComplianceStatsFailed({error: error.message}));
            }
        } else {
            const screenerComplianceStats = apiFetchScreenerComplianceStatsResponse.right();
            yield put(fetchedScreenerComplianceStats(screenerComplianceStats));
        }
    } catch (e) {
        console.log(e);
        yield put(fetchScreenerComplianceStatsFailed({error: "Error fetching screener compliance stats"}));
    } finally {
        // yield put(callFinished())
    }
}


const apiFetchScreenerPendingPatients = (action: {
    type: string,
    payload: {
        pageNumber: number,
        recordsPerPage: number,
        searchText: string,
        filters: TScreeningPendingPatientFiltersType
    },
    authState: AuthState
}) => {
    const query = createQueryString(action.payload);
    const url = `/compliance/monthly-screener-pending-patients?${query}`;
    return apiHttpClient.get<Either<CheckedError, IScreenerComplianceStats>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IScreenerComplianceStats);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e);
            const statusCode = e.response?.status || 500;
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse);
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"));
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText);
            return Either.left(new Error(e.message));
        });
}

function* fetchScreenerPendingPatients(action: {
    type: string,
    payload: {
        pageNumber: number,
        recordsPerPage: number,
        searchText: string,
        filters: TScreeningPendingPatientFiltersType
    },
    authState: AuthState
}) {
    try {
        console.log(`NonPhysicianComplianceSaga: fetchScreenerPendingPatients called with ${JSON.stringify(action)}`);
        yield put(callInProgress());
        yield put(fetchScreenerPendingPatientsInProgress());
        const apiFetchScreenerPendingPatientsResponse: Either<CheckedError, IScreenerPendingPatients> = yield call(apiFetchScreenerPendingPatients, action);
        if (apiFetchScreenerPendingPatientsResponse.isLeft()) {
            const error = apiFetchScreenerPendingPatientsResponse.left();
            if (error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message);
                yield put(fetchScreenerPendingPatientsFailed({error: error.message}));
            } else {
                yield put(fetchScreenerPendingPatientsFailed({error: error.message}));
            }
        } else {
            const screenerPendingPatients = apiFetchScreenerPendingPatientsResponse.right();
            yield put(fetchedScreenerPendingPatients(screenerPendingPatients?.patients || [], screenerPendingPatients?.count || 0));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchScreenerPendingPatientsFailed({error: "Error fetching screener pending patients"}));
    } finally {
        yield put(callFinished());
    }
}


const apiFetchClinicalNotesComplianceStats = (action: {
    type: string,
    payload: {
        request: TPendingClinicalNotesStatsRequest
    },
    authState: AuthState
}) => {
    const { practiceIds, ...queryObjectWithoutPracticeIds } = {
        ...action.payload.request, 
        practiceIds: action.payload.request?.practiceIds ? action.payload.request.practiceIds : null
    };
    const practiceIdsString = practiceIds ? practiceIds.join(',') : null 
    const query = createQueryString({...queryObjectWithoutPracticeIds, practiceIds: practiceIdsString});
    
    const url = `/compliance/social-worker-pending-clinical-note-stats?${query}`;
    return apiHttpClient.get<Either<CheckedError, IClinicalNotesComplianceStats>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IClinicalNotesComplianceStats);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e);
            const statusCode = e.response?.status || 500;
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse);
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"));
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchClinicalNotesComplianceStats(action: {
    type: string,
    payload: {
        request: TPendingClinicalNotesStatsRequest
    },
    authState: AuthState
}) {
    try {
        console.log(`NonPhysicianComplianceSaga: fetchClinicalNotesComplianceStats called with ${JSON.stringify(action)}`)
        // yield put(callInProgress());
        yield put(fetchClinicalNotesComplianceStatsInProgress());
        const apiFetchClinicalNotesComplianceStatsResponse: Either<CheckedError, IClinicalNotesComplianceStats> = yield call(apiFetchClinicalNotesComplianceStats, action);
        if (apiFetchClinicalNotesComplianceStatsResponse.isLeft()) {
            const error = apiFetchClinicalNotesComplianceStatsResponse.left();
            if (error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message);
                yield put(fetchClinicalNotesComplianceStatsFailed({error: error.message}));
            } else {
                yield put(fetchClinicalNotesComplianceStatsFailed({error: error.message}));
            }
        } else {
            const screenerComplianceStats = apiFetchClinicalNotesComplianceStatsResponse.right();
            yield put(fetchedClinicalNotesComplianceStats(screenerComplianceStats));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchClinicalNotesComplianceStatsFailed({error: "Error fetching screener compliance stats"}));
    } finally {
        // yield put(callFinished())
    }
}


const apiFetchPendingClinicalNotes = (action: {
    type: string,
    payload: {
        request: TPendingClinicalNotesListRequest
    },
    authState: AuthState
}) => {
    const { practiceIds, ...queryObjectWithoutPracticeIds } = {
        ...action.payload.request, 
        practiceIds: action.payload.request?.practiceIds ? action.payload.request.practiceIds : null
    };
    const practiceIdsString = practiceIds ? practiceIds.join(',') : null 
    const query = createQueryString({...queryObjectWithoutPracticeIds, practiceIds: practiceIdsString});
    
    const url = `/compliance/social-worker-pending-clinical-notes?${query}`;
    return apiHttpClient.get<Either<CheckedError, IScreenerComplianceStats>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IPendingClinicalNotes);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e);
            const statusCode = e.response?.status || 500;
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse);
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"));
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText);
            return Either.left(new Error(e.message));
        });
}

function* fetchPendingClinicalNotes(action: {
    type: string,
    payload: {
        request: TPendingClinicalNotesListRequest
    },
    authState: AuthState
}) {
    try {
        console.log(`NonPhysicianComplianceSaga: fetchPendingClinicalNotes called with ${JSON.stringify(action)}`);
        yield put(callInProgress());
        yield put(fetchPendingClinicalNotesInProgress());
        const apiFetchPendingClinicalNotesResponse: Either<CheckedError, IPendingClinicalNotes> = yield call(apiFetchPendingClinicalNotes, action);
        if (apiFetchPendingClinicalNotesResponse.isLeft()) {
            const error = apiFetchPendingClinicalNotesResponse.left();
            if (error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message);
                yield put(fetchPendingClinicalNotesFailed({error: error.message}));
            } else {
                yield put(fetchPendingClinicalNotesFailed({error: error.message}));
            }
        } else {
            const pendingClinicalNotes = apiFetchPendingClinicalNotesResponse.right();
            yield put(fetchedPendingClinicalNotes(pendingClinicalNotes?.notes || [], pendingClinicalNotes?.count || 0));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchScreenerPendingPatientsFailed({error: "Error fetching screener pending patients"}));
    } finally {
        yield put(callFinished());
    }
}

export default function* nonPhysicianComplianceSaga() {
    yield takeLatest(FETCH_SCREENER_COMPLIANCE_STATS, fetchScreenerComplianceStats);
    yield takeLatest(FETCH_SCREENER_PENDING_PATIENTS, fetchScreenerPendingPatients);
    yield takeLatest(FETCH_CLINICAL_NOTES_COMPLIANCE_STATS, fetchClinicalNotesComplianceStats);
    yield takeLatest(FETCH_PENDING_CLINICAL_NOTES, fetchPendingClinicalNotes);
}
