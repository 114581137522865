import {all, fork} from 'redux-saga/effects';

import accountSaga from "./counsellor/account.saga";
import physicianAccountSaga from "./physician/account.physician.saga"
import physicianAdminOperationsSaga from "./physician/admin-operations.saga"
import physicianPatientsSaga from "./physician/patients.saga"
import prospectsSaga from './counsellor/prospects.saga';
import appointmentsSaga from "./counsellor/appointments.saga";
import patientsSaga from "./counsellor/patients.saga";
import coordinatorPatientsSaga from "./care-coordinator/patients.saga"
import callsSaga from "./counsellor/calls.saga";
import practiceSaga from "./counsellor/practice.saga"
import patientsDashboardSaga from './physician/dashboard.saga';
import nonPhysicianComplianceSaga from "./counsellor/non-physician-compliance.saga";
import intakeSaga from "./counsellor/intake.saga";
import careCoordinatorComplianceSaga from "./care-coordinator/compliance.saga"

export function* rootSaga() {
    yield all([
        fork(physicianAccountSaga),
        fork(physicianPatientsSaga),
        fork(coordinatorPatientsSaga),
        fork(accountSaga),
        fork(prospectsSaga),
        fork(appointmentsSaga),
        fork(patientsSaga),
        fork(callsSaga),
        fork(practiceSaga),
        fork(physicianAdminOperationsSaga),
        fork(patientsDashboardSaga),
        fork(nonPhysicianComplianceSaga),
        fork(intakeSaga),
        fork(careCoordinatorComplianceSaga)
    ])
}
