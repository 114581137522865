import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearReferStatus, fetchPatients} from "../../../store/actions/physician/patients.action";
import {StateParams} from "../../../store/reducers";
import {IPatient, IPatientIndexItem, IPatientListItem} from "../../../models";
import Prospects from "../../../components/patients/physician/Prospects";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {RoutePaths} from "../../../shared/Utils";
import PaginationV2 from "../../../components/generic/PaginationV2";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import {InformationCircleIcon} from '@heroicons/react/24/outline'
import StatusTabs from "../../../components/generic/StatusTabs";

const TABS = [
    { label: "Potential Referrals", value: "potentialReferrals" },
    { label: "All Patients", value: "all" }
];

const ProspectsScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchedPatients = useSelector((state: StateParams) => state.physicianPatients.patients)
    const patientSearchInProgress = useSelector((state: StateParams) => state.physicianPatients.fetchPatientsInProgress);
    const { recordsCount, referStatus } = useSelector((state: StateParams) => state.physicianPatients)
    const { defaultLocation } = useSelector((state: StateParams) => state.physicianAccount)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [patients, setPatients] = useState<IPatientListItem[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "potentialReferrals");
    const {role } = useSelector((state: StateParams) => state.account)


    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        setActiveTab(status);
    }

    useEffect(() => {
        if(referStatus === "referred"){
            toast("Patient has been successfully referred to CoCM")
        }
    }, [referStatus])

    useEffect(()=>{
        dispatch(clearReferStatus())
    },[])

    useEffect(()=>{
        if(searchParams) {
            setCurrentPage(Number(searchParams.get("page")))
            setActiveTab(searchParams.get("tab") || "potentialReferrals")
        }
    },[searchParams])

    useEffect(() => {
        if (fetchedPatients) {
            setPatients(fetchedPatients)
        }
    }, [fetchedPatients])

    useEffect(() => {
        if(currentPage || recordsPerPage || searchText) {
            const isPotentialReferrals = activeTab.includes("potentialReferrals")
            dispatch(fetchPatients(currentPage, recordsPerPage, searchText, isPotentialReferrals))
        }
    }, [currentPage, recordsPerPage, searchText, activeTab, defaultLocation]);

    useEffect(() => {
        if(role === 'Medical_Assistant'){
            navigate(`${RoutePaths.medicalAssistance.prospects}?page=${currentPage}&tab=${activeTab}`)
        }else{
            navigate(`${RoutePaths.physicianCare.prospects}?page=${currentPage}&tab=${activeTab}`)
        }
    }, [currentPage, activeTab]);

    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"
    };

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            <h1 className="text-xl"> Patients </h1>
            <div className="mt-4">
                <div className={"flex justify-between pb-2 items-center"}>
                    <div className={"flex justify-between gap-x-2 items-center"}>
                        <div className="inline-flex rounded-md shadow-sm">
                            <StatusTabs
                                tabs={TABS}
                                handleSelectedFilters={handleSelectedFilters}
                                getTabClassName={getStatusTabClassName}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        <TableFilterPaginationV2
                            setTableFilter={setSearchText}
                            totalRows={recordsCount || 0}
                            currentPageHandler={setCurrentPage}
                        />
                    </div>
                </div>
                <div className="flex flex-row mt-4 items-center gap-x-2">
                    <InformationCircleIcon width={16} height={16}/>
                    <span className="text-base text-[#575F6E] font-light">Please refresh to see updated patient list</span>
                </div>
                <Prospects patients={patients} searchInProgress={patientSearchInProgress}/>
                {patients && patients.length > 0 &&
                    <PaginationV2
                        totalRows={recordsCount || 0}
                        rowsPerPage={recordsPerPage}
                        recordsPerPageHandler={setRecordsPerPage}
                        currentPage={currentPage}
                        currentPageHandler={setCurrentPage}
                        showPages={false}
                    />
                }
            </div>
        </div>
    )
}

export default ProspectsScreen
