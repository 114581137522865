import { DateTime } from "luxon";

export const convertISODateToMMDDYYYY = (dateString: string, format: string = 'MM/dd/yyyy', zone = 'utc'): string | null => {
    if (!dateString || !format) return null
    try {
        const parsedDate = DateTime.fromISO(dateString, { zone })
        return parsedDate.toFormat(format)
    } catch(error) {
        console.error('Error parsing date string:', error)
        return null
    }
}