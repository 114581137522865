import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoutePaths } from "../../../shared/Utils";
import { fetchPotentialReferralPatients } from "../../../store/actions/care-coordinator/patients.action";
import PotentialReferrals from "../../../components/patients/care-coordinator/PotentialReferrals";
import { IPotentialReferralPatient } from "../../../models";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import PaginationV2 from "../../../components/generic/PaginationV2";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { fetchPractices } from "../../../store/actions/counsellor/prospects.action";
import StatusTabs from "../../../components/generic/StatusTabs";
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { Tooltip } from "react-tooltip";
import { Button } from "../../../components/generic/Buttons";
import { useFlags } from "flagsmith/react";

export enum CallStatus {
    ALL = 'all',
    PENDING = 'pending',
    NOANSWER = 'no_answer',
    CALLAGAIN = 'call_again',
    INTERESTED = 'interested',
    NOTINTERESTED = 'not_interested',
}

export type Filters = {
    practiceId: string | null
    startDate: string | null
    endDate: string | null
}

const TABS = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "No Answer", value: "no_answer" },
    { label: "Call Again", value: "call_again" },
    { label: "Interested", value: "interested" },
    { label: "Not Interested", value: "not_interested" }
]

const PotentialPatients = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { potentialReferralPatients, fetchPotentialReferralPatientsInProgress } = useSelector((state: StateParams) => state.coordinatorPatients)
    const [patients, setPatients] = useState<IPotentialReferralPatient[]>([]);
    const recordsCount = useSelector((state: StateParams) => state.coordinatorPatients.potentialReferralPatientCount)
    const statusCount = useSelector((state: StateParams) => state.coordinatorPatients.potentialReferralStatusCount)
    const { practices } = useSelector((state: StateParams) => state.prospects)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [statusFilters, setStatusFilters] = useState<string[]>(["pending"])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { control, handleSubmit, register, watch, reset } = useForm();
    const [associatedPractices, setAssociatedPractices] = useState<{ label: string, value: string }[]>([])
    const [advancedFilters, setAdvancedFilters] = useState<Filters>();
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "pending")
    const [startDate, setStartDate] = useState<string | null>(null)
    const [endDate, setEndDate] = useState<string | null>(null)
    const flags = useFlags(['cc_potential_patients_screening_date_range_filter'])
    const isPotentialPatientsDateRangeFilterEnabled = flags.cc_potential_patients_screening_date_range_filter.enabled


    useEffect(() => {
        dispatch(fetchPractices())
    }, [])

    useEffect(() => {
        if (practices) {
            if (practices && Array.isArray(practices)) {
                setAssociatedPractices(practices.map(practice => ({
                    label: `${practice.name}`,
                    value: practice.id
                })))
            }
            setIsLoading(false)
        }
    }, [practices])

    useEffect(() => {
        const subscription = watch((value: any, { name, type }) => {
            if (name && type === 'change') {
                setAdvancedFilters(value)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleApply = () => {
        setCurrentPage(1);

        setAdvancedFilters((prev: any) => {
            const formattedStartDate = startDate ? DateTime.fromISO(startDate).toFormat("yyyy-MM-dd") : null;
            const formattedEndDate = endDate ? DateTime.fromISO(endDate).toFormat("yyyy-MM-dd") : DateTime.now().toFormat("yyyy-MM-dd");

            return {
                ...prev,
                startDate: formattedStartDate && formattedStartDate,
                endDate: formattedEndDate && formattedEndDate
            };
        });
    };



    useEffect(() => {
        if (currentPage || recordsPerPage) {
            dispatch(fetchPotentialReferralPatients(currentPage, recordsPerPage, searchText, { status: statusFilters.length > 0 ? statusFilters : undefined }, advancedFilters))
        }
    }, [currentPage, recordsPerPage, searchText, statusFilters, advancedFilters]);

    useEffect(() => {
        const page = Number(searchParams.get("page"));
        if (page > 0) {
            setCurrentPage(Number(searchParams.get("page")));
        }
    }, [searchParams])

    useEffect(() => {
        if (potentialReferralPatients) {
            setPatients(potentialReferralPatients)
        }
    }, [potentialReferralPatients])

    useEffect(() => {
        navigate(`${RoutePaths.careCoordinator.potentialPatients.root}?page=${currentPage}`)
    }, [currentPage]);

    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        if (status === "all") {
            setStatusFilters([])
            return
        }
        setStatusFilters([status])
    }

    const onChangeValue = (e: any) => {
        if (e.target.id === 'startDate') {
            if (e.target.value) {
                const isoStartDate = DateTime.fromISO(e.target.value).toLocal().toFormat('yyyy-MM-dd');
                setStartDate(isoStartDate);
            } else {
                setStartDate(null);
            }
        } else {
            if (e.target.value) {
                const isoEndDate = DateTime.fromISO(e.target.value).endOf('day').toLocal().toFormat('yyyy-MM-dd');
                setEndDate(isoEndDate);
            } else {
                setEndDate(null); // Clear end date
            }
        }
    };

    return (
        <div className="w-full p-4 h-full bg-[#F7F8FA]">
            <div className={"flex justify-between"}>
                <h1 className="text-[20px] leading-27">Potential Patients</h1>
                {!isLoading && <div className="flex justify-end items-center gap-x-2">
                    <>

                        {isPotentialPatientsDateRangeFilterEnabled &&
                            <div className='flex flex-row gap-x-2 items-center'>
                                <input
                                    id="startDate"
                                    type="date"
                                    required
                                    max={DateTime.now().toFormat('yyyy-MM-dd')}
                                    value={startDate ? DateTime.fromISO(startDate).toFormat('yyyy-MM-dd') : ""}
                                    onChange={(e) => onChangeValue(e)}
                                    className="w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                                />

                                <span>to</span>

                                <input
                                    id="endDate"
                                    type="date"
                                    required
                                    value={endDate ? DateTime.fromISO(endDate).toFormat('yyyy-MM-dd') : ""}
                                    min={startDate ? DateTime.fromISO(startDate).toFormat('yyyy-MM-dd') : ""}
                                    max={DateTime.now().toFormat('yyyy-MM-dd')} // Ensure end date can't be in the future
                                    onChange={(e) => onChangeValue(e)}
                                    className="w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                                />

                                <Button
                                    data-tooltip-id="invalid-date-selection-tooltip"
                                    className="!bg-sjOrange !text-white"
                                    onClick={handleApply}>Apply</Button>
                            </div>
                        }


                    </>

                    <div className="w-80">
                        <Controller
                            control={control}
                            name='practiceId'
                            render={({ field }) => (
                                <Select
                                    className="border-gray-400 rounded text-center"
                                    options={associatedPractices}
                                    value={associatedPractices.find(x => x.value === field.value)}
                                    onChange={x => field.onChange(x.map(y => y?.value))}
                                    isMulti={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={'Select Physician'}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: 'gray',
                                            height: 22, // adjust the height to your liking
                                            minHeight: 32
                                        }),
                                        placeholder: (style) => ({
                                            ...style,
                                            paddingBottom: 8,
                                            textAlign: 'center'
                                        }),
                                        multiValue: (style) => ({
                                            ...style,
                                            marginTop: -5
                                        }),

                                    }}
                                />
                            )}
                        />
                    </div>

                </div>}
            </div>

            <div className={"flex justify-between pb-4 mt-4"}>
                <div className="inline-flex rounded-md shadow-sm">
                    <a href="#" aria-current="page"
                        onClick={() => handleSelectedFilters(CallStatus.ALL)}
                        className={`${statusFilters.length === 0 ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-s-lg border border-gray-200`}>
                        All ({statusCount.all || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.PENDING)}
                        className={`${statusFilters.includes(CallStatus.PENDING) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Pending ({statusCount.pending || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.NOANSWER)}
                        className={`${statusFilters.includes(CallStatus.NOANSWER) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        No Answer ({statusCount.no_answer || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.CALLAGAIN)}
                        className={`${statusFilters.includes(CallStatus.CALLAGAIN) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Call Again ({statusCount.call_again || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.INTERESTED)}
                        className={`${statusFilters.includes(CallStatus.INTERESTED) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                        Interested ({statusCount.interested || 0})
                    </a>
                    <a href="#"
                        onClick={() => handleSelectedFilters(CallStatus.NOTINTERESTED)}
                        className={`${statusFilters.includes(CallStatus.NOTINTERESTED) ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-e-lg border border-gray-200`}>
                        Not Interested ({statusCount.not_interested || 0})
                    </a>
                </div>
                <div className="flex gap-x-2 justify-end">
                    <TableFilterPaginationV2
                        setTableFilter={setSearchText}
                        totalRows={recordsCount || 0}
                        currentPageHandler={setCurrentPage}
                    />
                </div>

            </div>
            <PotentialReferrals patients={patients} searchInProgress={fetchPotentialReferralPatientsInProgress} statusFilters={statusFilters} />
            {patients && patients.length > 0 &&
                <PaginationV2
                    totalRows={recordsCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={currentPage}
                    currentPageHandler={setCurrentPage}
                    showPages={false}
                />
            }
        </div>
    )
}

export default PotentialPatients
