import React from "react";
import "../PhysicianDashboardScreen.scss";
import {ImageConfig} from "../../../../constants";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";

interface PhysicianDashboardStatisticsComponentProps {

}

const PhysicianDashboardStatisticsComponent = (props: PhysicianDashboardStatisticsComponentProps) => {

    const {patientsSummary, fetchPatientsSummaryInProgress} = useSelector((state: StateParams) => state.patientsDashboard);


    const renderPhysicianDashboardStatisticsCard = (label: string, count: number | undefined, percentage?: number| undefined) => {
        return (
            <div className={`physician-dashboard-statistics-card`}>
                {fetchPatientsSummaryInProgress && <div className="card-loading"/>}
                <div className="physician-dashboard-statistics-card-label-and-count">
                    <div className="physician-dashboard-statistics-card-count">
                        {count || 0}
                    </div>
                    <div className="physician-dashboard-statistics-card-label">
                        {label}
                    </div>
                </div>
                {percentage && <div className="physician-dashboard-statistics-card-percentage">
                    {percentage}% <ImageConfig.StatsIcon/>
                </div>}
            </div>
        )
    }

    return (
        <div className="physician-dashboard-statistics">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {renderPhysicianDashboardStatisticsCard("No. of Locations", patientsSummary?.locationsCount)}
                {renderPhysicianDashboardStatisticsCard("Patients screened", patientsSummary?.patientsScreened)}
                {renderPhysicianDashboardStatisticsCard("Patients referred", patientsSummary?.patientsReferred.count, patientsSummary?.patientsReferred.percentage)}
                {renderPhysicianDashboardStatisticsCard("Patients enrolled in CoCM", patientsSummary?.patientsEnrolled.count, patientsSummary?.patientsEnrolled.percentage)}
            </div>
        </div>
    )
}

export default PhysicianDashboardStatisticsComponent;
