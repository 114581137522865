import React from "react";
import {IPatient, ISummary} from "../../../models";
import PatientProfile from "./PatientProfile";
import ProspectResults from "./ProspectResults";

interface PatientProps {
    patient: IPatient
    summary: ISummary
}

const Prospect : React.FC<PatientProps> = ({patient, summary}) => {

    return <>
        <PatientProfile patient={patient}/>
        {patient.screeners.length>0 ? 
            <ProspectResults patient={patient} summary={summary}/> : 
            <div className="mt-2">
                <div className="text-sjOrange p-2">Summary</div>
                <hr/>
                <div className="mt-6 p-4 text-center">The patient is not screened yet</div>
            </div>
        }
        
    </>
}

export default Prospect
